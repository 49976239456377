import {AuthLayoutComponent} from './@dubistpflege/layouts/auth-layout/auth-layout.component';
import {AdminLayoutComponent} from './@dubistpflege/layouts/admin-layout/admin-layout.component';
import {UserResolver} from './@dubistpflege/services/user.resolver';
import {Routes} from '@angular/router';
import {AuthGuard} from './@dubistpflege/security-guards/auth.guard';
import {AuthPassGuard} from './@dubistpflege/security-guards/authpass.guard';

export const routes: Routes = [
    {
        path: 'auth',
        component: AuthLayoutComponent,
        loadChildren: () => import('./auth/auth.module').then(m => m.AuthModule),
        data: {
            title: 'Auth'
        },
        canActivate: [AuthPassGuard]
    },

    {
        path: '',
        component: AdminLayoutComponent,
        resolve: {user: UserResolver},
        loadChildren: () => import('./admin/admin.module').then(m => m.AdminModule),
        data: {
            title: 'Admin'
        },
        canActivate: [AuthGuard]
    },

    {
        path: '',
        redirectTo: '/users',
        pathMatch: 'full'
    },
    {
        path: '**',
        redirectTo: 'dashboard'
    }
];
