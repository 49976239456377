<!-- ORDER FORM -->
<form *ngIf="!showConfigureFacilitiesQuestion" [formGroup]="orderForm" (ngSubmit)="submitRegistration()" class="new-order-modal">

    <!-- Heading -->
    <div class="heading" [ngStyle]="orderForm.valid && {'color':'#0eb370'}">
        <div class="w-100 d-flex justify-content-center">
            <div>Bestellungsdaten eingeben.</div>
            <div style="position: relative;min-width: 31px;">
                <i *ngIf="orderForm.valid" class="bi bi-check-circle ml-2" style="font-size: 23px;"></i>
            </div>
        </div>
    </div>

    <div class="d-flex" style="column-gap: 40px;align-items: center;">
        <h1 [ngStyle]="orderForm.get('email').valid && orderForm.get('number_of_facilities').valid && {'color':'#0eb370', opacity: 0.8}">1</h1>
        <div class="w-100">
            <!-- E-Mail, Anzahl Standorte -->
            <div class="form-row">
                <div class="form-group col-md-7">
                    <label for="inputEmail4">E-Mail (Bestellung & justappl.ai Account E-Mail)<span class="required-form-field">*</span></label>
                    <input type="email" formControlName="email" class="form-control" id="inputEmail4" placeholder="E-Mail">
                    <small class="text-danger" *ngIf="orderForm.get('email').errors?.email">Die E-Mail Adresse ist ungültig.</small>
                </div>
                <div class="form-group col-md-5">
                    <label for="inputEmail4">Anzahl der Standorte<span class="required-form-field">*</span></label>
                    <input type="text" formControlName="number_of_facilities" class="form-control" id="inputEmail4" placeholder="Anzahl">
                    <small class="text-danger" *ngIf="orderForm.get('number_of_facilities').value && orderForm.get('number_of_facilities').errors">
                        In diesem Feld sind nur Nummern größer als 0 erlaubt.
                    </small>
                </div>
            </div>
            <!-- Mitgliedschaft -->
            <div *ngIf="membershipOrganisations.length > 0" class="form-row mt-1 mb-1">
                <div class="form-group col-md-7">
                    <label for="inputPassword4">Mitglied bei</label>
                    <select formControlName="membership_organisation" id="inputState" class="form-control">
                        <option value="" disabled selected hidden style="color: #495057 !important;">Wählen Sie eine Organisation aus...</option>
                        <option [selected]="orderForm.get('membership_organisation').value === organisation.name" *ngFor="let organisation of membershipOrganisations">{{organisation.name | translate}}</option>
                        </select>
                </div>
                <div class="form-group col-md-5">
                    <label for="inputPassword4">Mitgliedsnummer</label>
                    <input type="text" formControlName="membership_nr" class="form-control" id="inputPassword4" placeholder="Mitgliedsnummer">
                </div>
            </div>
        </div>
    </div>

    <!-- Separating line -->
    <div style="margin: 8px 0 14px;">
        <div class="w-100" style="border-bottom: 1px solid rgba(0, 0, 0, 0.12);"></div>
    </div>

    <div class="d-flex" style="column-gap: 40px;align-items: center;">
        <h1 [ngStyle]="orderForm.get('company_name').valid && 
                        orderForm.get('zip').valid && 
                        orderForm.get('city').valid && 
                        orderForm.get('street').valid &&
                        orderForm.get('contact_name').valid &&
                        orderForm.get('contact_email').valid && {'color':'#0eb370', opacity: 0.8}">2</h1>
        <div class="w-100">
            <!-- Unternehmen, Kostenstelle -->
            <div class="form-row mt-1">
                <div class="form-group col-md-7">
                    <label for="inputAddress">Unternehmensname<span class="required-form-field">*</span></label>
                    <input type="text" formControlName="company_name" class="form-control" id="inputAddress" placeholder="Unternehmensname">
                </div>
                <div class="form-group col-md-5">
                    <label for="inputAddress">Kostenstelle / Kundenzeichen</label>
                    <input type="text" formControlName="internal_ref_id" class="form-control" id="inputAddress" placeholder="Kostenstelle / Kundenzeichen">
                </div>
            </div>
        
            <!-- PLZ, Stadt, Straße -->
            <div class="form-row mt-1">
                <div class="form-group col-12 col-lg-3">
                    <label for="inputEmail4">Postleitzahl<span class="required-form-field">*</span></label>
                    <input type="text" formControlName="zip" class="form-control" id="inputEmail4" placeholder="Postleitzahl">
                    <small class="text-danger" *ngIf="orderForm.get('zip').value && orderForm.get('zip').errors">
                        Die Postleitzahl muss fünfstellig sein.
                    </small>
                </div>
                <div class="form-group col-12 col-lg-4">
                    <label for="inputEmail4">Stadt<span class="required-form-field">*</span></label>
                    <input type="text" formControlName="city" class="form-control" id="inputEmail4" placeholder="Stadt">
                </div>
                <div class="form-group col-md-5">
                    <label for="inputPassword4">Straße & Hausnr. / Postfach<span class="required-form-field">*</span></label>
                    <input type="text" formControlName="street" class="form-control" id="inputPassword4" placeholder="Straße & Hausnr. / Postfach">
                </div>
            </div>
        
            <!-- Kontaktperson -->
            <div class="form-row mb-1">
                <div class="form-group col-md-4">
                    <label for="inputEmail4">Kontaktperson<span class="required-form-field">*</span></label>
                    <input type="text" formControlName="contact_name" class="form-control" id="inputEmail4" placeholder="Vorname und Nachname">
                </div>
                <div class="form-group col-md-4">
                    <label for="inputEmail4">E-Mail (Kontakt)<span class="required-form-field">*</span></label>
                    <input type="email" formControlName="contact_email" class="form-control" id="inputEmail4" placeholder="E-Mail">
                    <small class="text-danger" *ngIf="orderForm.get('contact_email').errors?.email">Die E-Mail Adresse ist ungültig.</small>
                </div>
                <div class="form-group col-md-4">
                    <label for="inputPassword4">Telefonnummer</label>
                    <input type="text" formControlName="contact_phone" class="form-control" id="inputPassword4" placeholder="Telefonnummer">
                </div>
            </div>
        
            <!-- Standortsdaten identisch -->
            <div *ngIf="orderForm.get('number_of_facilities').value == 1 && orderForm.valid" class="form-group mt-1">
                <div class="form-check">
                    <input class="form-check-input" type="checkbox" id="facilityDataSameCheck" (click)="onFacilitySameAsCompanyCheckboxChange($event)">
                    <label class="form-check-label" for="facilityDataSameCheck">Standortsdaten sind identisch mit Unternehmensdaten.</label>
                    <div class="text-muted" style="font-size: 12px;font-weight: 500;margin-top: -2px;">(Wenn die Checkbox aktiviert ist, wird automatisch eine Standort angelegt)</div>
                </div>
                <form [formGroup]="facilityForm" *ngIf="facilityDataAreTheSameAsCompanyData">
                    <div class="contact-heading">Stammdaten der Standort (optional)</div>
                    <div class="form-row">
                        <div class="form-group col-md-6">
                            <label for="inputEmail4">E-Mail zur Auslieferung der Bewerber</label>
                            <input type="email" formControlName="application_delivery_email" class="form-control" id="inputEmail4" placeholder="E-Mail">
                            <small class="text-danger" *ngIf="facilityForm.get('application_delivery_email').errors?.email">Die E-Mail Adresse ist ungültig.</small>
                        </div>
                        <div class="form-group col-md-6">
                            <label for="inputPassword4">Unternehmenskategorie</label>
                            <select formControlName="company_category" id="inputState" class="form-control">
                                <option value="" disabled selected hidden style="color: #495057 !important;">Wählen Sie eine Kategorie aus...</option>
                                <option [selected]="facilityForm.get('company_category').value === category.name" *ngFor="let category of companyCategories">{{category.name}}</option>
                            </select>
                        </div>
                    </div>
                    <div class="form-row">
                        <div class="form-group col-md-6">
                            <label for="inputPassword4">Karriereseite</label>
                            <input type="text" formControlName="carrer_site_url" class="form-control" id="inputEmail4" placeholder="Link">
                            <small class="text-danger" *ngIf="facilityForm.get('carrer_site_url').value && facilityForm.get('carrer_site_url').errors">Der Link ist ungültig.</small>
                        </div>
                        <div class="form-group col-md-6">
                            <label for="inputPassword4">Domain (Website)</label>
                            <input type="text" formControlName="facility_domain_url" class="form-control" id="inputEmail4" placeholder="Link">
                            <small class="text-danger" *ngIf="facilityForm.get('facility_domain_url').value && facilityForm.get('facility_domain_url').errors">Der Link ist ungültig.</small>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>

    <!-- Separating line -->
    <div style="margin: 8px 0 14px;">
        <div class="w-100" style="border-bottom: 1px solid rgba(0, 0, 0, 0.12);"></div>
    </div>

    <div class="d-flex" style="column-gap: 40px;align-items: center;">
        <h1 [ngStyle]="orderForm.valid && {'color':'#0eb370', opacity: 0.8}">3</h1>
        <div class="w-100">
            <!-- Projekt und Preispakete -->
            <div *ngIf="projectConfigList?.length > 0" class="form-row mt-1">
                <div class="form-group col-md-6">
                    <label for="inputPassword4">Projekt {{orderForm.get('project').value}}<span class="required-form-field">*</span></label>
                    <select id="inputState" class="form-control" (change)="onSelectProjectChange($event)">
                        <!-- <option value="" disabled selected hidden style="color: #495057 !important;">Wähle ein Projekt aus...</option> -->
                        <option *ngFor="let projectConfig of projectConfigList" [selected]="orderForm.get('project').value === projectConfig.project" [value]="projectConfig.project">{{projectConfig.project}}</option>
                    </select>
                </div>
                <div class="form-group col-md-6">
                    <label for="inputPassword4">Preispaket<span class="required-form-field">*</span></label>
                    <select id="inputState" class="form-control" (change)="onSelectPricePackageChange($event)">
                        <!-- <option value="" disabled selected hidden style="color: #495057 !important;">Wähle ein Projekt aus...</option> -->
                        <option *ngFor="let pricePackage of selectedProject.price_packages" [selected]="orderForm.get('package_id').value === pricePackage.id" [value]="pricePackage.id">
                            <span *ngIf="pricePackage.number_of_facilities == null || pricePackage.number_of_facilities != orderForm.get('number_of_facilities').value">{{pricePackage.name}}: </span>
                            {{pricePackage.valid_time}} {{pricePackage.valid_time_unit}} - {{pricePackage.price_amount}} {{pricePackage.price_currency}} je Standort
                        </option>
                      </select>
                </div>
            </div>
        </div>
    </div>


    <div class="w-100 d-flex justify-content-center mt-4" style="column-gap: 10px;">
        <div class="btn btn-light" style="border: 2px solid rgba(0,0,0,0.4);color:rgba(0,0,0,0.7)" (click)="cancelOrderCreation()">ABBRECHEN</div>
        <button class="btn btn-primary" type="submit" [disabled]="!orderForm.valid" [class.disabled-button]="!orderForm.valid">ABSENDEN</button>
    </div>
</form>

<!-- DO YOU WANT TO CONFIGURE THE FACILITIES? -->
<div *ngIf="showConfigureFacilitiesQuestion" class="new-order-modal d-flex" style="flex-direction: column;align-items: center;">
    <div class="heading">
        <div>Möchtest du die Standort<span *ngIf="orderForm.get('number_of_facilities').value > 1">en</span> gleich bearbeiten?</div>
    </div>
    <div class="w-100 d-flex justify-content-center mt-2" style="column-gap: 10px;">
        <div class="btn btn-light" style="border: 2px solid rgba(0,0,0,0.4);color:rgba(0,0,0,0.7)" (click)="closeModalAfterSubmission(false)">NEIN</div>
        <div class="btn btn-primary" (click)="closeModalAfterSubmission(true)">JA</div>
    </div>
</div>
