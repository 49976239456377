<div class="container-fluid">
  <div class="row bg-white justify-content-center vh-100 auth-layout">
    <div class="col-11 col-md-7 col-lg-6 col-xl-5 auth-content">
      <div>
        <a routerLink="/" class="d-inline-block mt-4 pl-2">
          <img src="assets/dubistpflege-bl.png" height="30">
        </a>
      </div>
      <router-outlet></router-outlet>
    </div>
    <div class="col-5 col-lg-6 col-xl-7 background-content vh-100 d-none d-md-block" [class.register-page]="router.url.endsWith('/auth/register')">
      <a routerLink="/" class="close-auth" aria-label="Close">
        <img src="assets/icons/close-white.svg">
      </a>
    </div>
  </div>
</div>

