import {Directive, Input, ElementRef, Renderer2, HostListener, EventEmitter, Output} from '@angular/core';


@Directive({
    selector: '[appSort]'
})
export class SortDirective {
    @Output('order') order = new EventEmitter<string>();
    @Input() appSort: Array<any>;

    private sortOrder = 1;
    private collator = new Intl.Collator(undefined, {
        numeric: true,
        sensitivity: 'base',
    });


    constructor(private renderer: Renderer2, private targetElem: ElementRef) {
    }

    @HostListener("click")
    sortData() {
        this.sortOrder = 1;
        this.collator = new Intl.Collator(undefined, {
            numeric: true,
            sensitivity: 'base',
        });


        const elem = this.targetElem.nativeElement;
        const order = elem.getAttribute('data-order');
        const type = elem.getAttribute('data-type');
        const nested_key = elem.getAttribute('data-nested-key');
        const property = elem.getAttribute('data-name');


        if (order === 'desc') {
            this.appSort.sort(this.startSort(property, order, nested_key, type));
            elem.setAttribute('data-order', 'asc');
        } else {
            this.appSort.sort(this.startSort(property, order, nested_key, type));
            elem.setAttribute('data-order', 'desc');
        }
    }


    startSort(property, order, nested_key = null, type = "") {
        if (order === 'desc') {
            this.sortOrder = -1;
        }
        return (a, b) => {
            if (nested_key) {
                if (a[property] && a[property][nested_key]) {
                    return this._sortData(a[property][nested_key], b[property][nested_key]);
                }
            } else if (type === 'desc') {
                return this._sortData(new Date(a[property]), new Date(b[property]));
            } else {
                return this.collator.compare(a[property], b[property]) * this.sortOrder;
            }
        };
    }

    _sortData(a, b) {
        return a < b ? -1 * this.sortOrder : a > b ? 1 * this.sortOrder : 0 * this.sortOrder
    }


}
