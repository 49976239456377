import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { MEMBERSHIP_ORGANISATIONS, COMPANY_CATEGORIES } from '../../constants/constants';
import { FacilityService } from '../../services/facility.service';
import {v4 as uuidv4} from 'uuid';
import { AuthService } from '../../services/auth.service';
import * as _ from 'lodash';
import { distinctUntilChanged } from 'rxjs/operators';

@Component({
  selector: 'app-new-order-modal',
  templateUrl: './new-order-modal.component.html',
  styleUrls: ['./new-order-modal.component.scss']
})
export class NewOrderModalComponent implements OnInit {
  
  sessionID: string;
  companyCategories = COMPANY_CATEGORIES;
  membershipOrganisations: any;
  orderData: any;
  orderForm: any;
  orderID: string;
  facilityDataAreTheSameAsCompanyData: boolean = false;
  facilityGenerated: boolean = false;
  facilityForm: any;
  showConfigureFacilitiesQuestion: boolean = false;
  projectConfigList: any = [];
  projectConfigListCopy: any[];
  selectedProject: any;

  constructor(private _facilityService: FacilityService,
              private _fb: FormBuilder,
              private _activeModal: NgbActiveModal,
              private _spinner: NgxSpinnerService,
              private _authService: AuthService) { }

  ngOnInit(): void {
    this.setMembershipOrganisations();
    if (sessionStorage.getItem("session_id")) {
      this.sessionID = sessionStorage.getItem("session_id");
    } else {
      this.sessionID = uuidv4();
      sessionStorage.setItem("session_id", this.sessionID);
    }
    this.initializeOrderForm();
    this.getAllProjectConfigs(true);
    this.onNumberOfFacilitiesChange();
  }

  onNumberOfFacilitiesChange() {
    this.orderForm.get("number_of_facilities").valueChanges.pipe(
      distinctUntilChanged() // only emit new value when it is different from the previous value
    ).subscribe(value => {
      if (Number(value) > 1) {
        this.facilityDataAreTheSameAsCompanyData = false;
      }
      if (Number(value) > 0) {
        this.projectConfigList = _.cloneDeep(this.projectConfigListCopy);

        this.projectConfigList = this.projectConfigList.filter(projectConfig => {
          let pricePackagesHaveNumOfFacilities = projectConfig.price_packages.some(pricePackage => pricePackage.number_of_facilities == Number(value));
          let nearestNumber = null;
          projectConfig.price_packages = projectConfig.price_packages.filter((pricePackage, index) => {
            if (index == 0) {
              nearestNumber = this.findNearestPricePackageFacilitiesNumber(projectConfig.price_packages, Number(value));
            }
            if (pricePackage.number_of_facilities != null) {
              if (pricePackagesHaveNumOfFacilities) {
                return pricePackage.number_of_facilities == Number(value);
              } else {
                return pricePackage.number_of_facilities == nearestNumber;
              }
            } else {
              return true;
            }
          });
          return projectConfig.price_packages.length > 0;
        });
      } else {
        this.projectConfigList = _.cloneDeep(this.projectConfigListCopy);
      }
      if (value != "") {
        if (this.projectConfigList.some(projectConfig => projectConfig.project == this.orderForm.get('project').value)) {
          this.selectProject(this.orderForm.get('project').value);
        } else if (this.projectConfigList.some(config => config.project == "general")) {
          this.selectProject("general");
          this.orderForm.get('project').setValue("general");
        } else {
          this.selectProject(this.projectConfigList[0].project);
          this.orderForm.get('project').setValue(this.projectConfigList[0].project);
        }
      } else {
        this.projectConfigList = _.cloneDeep(this.projectConfigListCopy);
        let config = this.projectConfigList.find(config => config.project == "general");
        if (this.projectConfigList.some(projectConfig => projectConfig.project == this.orderForm.get('project').value)) {
          this.selectProject(this.orderForm.get('project').value);
        } else if (config) {
          this.selectedProject = config;
          this.orderForm.get('project').setValue("general");
        } else {
          this.selectedProject = this.projectConfigList[0];
          this.orderForm.get('project').setValue(this.selectedProject.project);
        }
        if (!this.selectedProject.price_packages.some(pricePackage => pricePackage.id == this.orderForm.get('package_id').value)) {
          this.orderForm.get('package_id').setValue(null);
          this.orderForm.get('valid_time').setValue(null);
          this.orderForm.get('valid_time_unit').setValue(null);
          this.orderForm.get('price_amount').setValue(null);
          this.orderForm.get('price_currency').setValue(null);
        }
      }
    });
  }

  findNearestPricePackageFacilitiesNumber(pricePackages, target) {
    let nearestNumber = null;
    for (let i = 0; i < pricePackages.length; i++) {
      if (pricePackages[i].number_of_facilities === target) {
        // if the target number is present in the array, return it
        return pricePackages[i].number_of_facilities;
      } else if (pricePackages[i].number_of_facilities < target &&
        (nearestNumber === null || pricePackages[i].number_of_facilities > nearestNumber)) {
        // if the current number is smaller than the target number
        // and larger than the current nearest number, update the
        // nearest number to the current number
        nearestNumber = pricePackages[i].number_of_facilities;
      }
    }
    return nearestNumber;
  }

  getAllProjectConfigs(init?: boolean) {
    this._spinner.show();
    this._facilityService.getAllProjectConfigs().subscribe(res => {
      let user = this._authService.getUser();
      if (user["project_ids"]?.length > 0) {
        this.projectConfigList = res.filter(el => user["project_ids"].includes(el["id"]));
      } else {
        this.projectConfigList = res;
      }
      this.projectConfigListCopy = _.cloneDeep(this.projectConfigList);
      if (this.projectConfigList.some(config => config.project == "general")) {
        this.selectProject("general", true);
      } else {
        this.selectProject(this.projectConfigList[0]?.project, true);
      }
      this._spinner.hide();
    });
  }

  onSelectProjectChange(event) {
    let projectName = event.target.value;
    this.selectProject(projectName);
  }

  onSelectPricePackageChange(event) {
    let pricePackageID = event.target.value;
    let selectedPricePackage = this.selectedProject.price_packages.find(pricePackage => pricePackage.id == pricePackageID);
    this.orderForm.get('package_id').setValue(selectedPricePackage.id);
    this.orderForm.get('valid_time').setValue(selectedPricePackage.valid_time);
    this.orderForm.get('valid_time_unit').setValue(selectedPricePackage.valid_time_unit);
    this.orderForm.get('price_amount').setValue(selectedPricePackage.price_amount);
    this.orderForm.get('price_currency').setValue(selectedPricePackage.price_currency);

    if (selectedPricePackage.number_of_facilities != null) {
      this.orderForm.get('number_of_facilities').setValue(selectedPricePackage.number_of_facilities);
    }
  }

  selectProject(projectName: string, init?: boolean) {
    this.selectedProject = _.cloneDeep(this.projectConfigList.find(project => project.project == projectName));

    // 1. Filter the price packages of the selected project so it fits the number_of_facilities if set (if not set, dont filter)
    let pricePackagesHaveNumOfFacilities = this.selectedProject.price_packages.some(pricePackage => pricePackage.number_of_facilities == this.orderForm.get("number_of_facilities").value);
    this.selectedProject.price_packages = this.selectedProject.price_packages.filter(pricePackage => {
      if (pricePackage.number_of_facilities != null && this.orderForm.get("number_of_facilities").value != "") {
        if (pricePackagesHaveNumOfFacilities) {
          return pricePackage.number_of_facilities == this.orderForm.get("number_of_facilities").value;
        } else {
          return true;
        }
      } else {
        return true;
      }
    });

    // 2. Select the first price package of that filtered list
    if (this.orderForm.get('project').value != this.selectedProject.project) {
      this.orderForm.get('project').setValue(this.selectedProject.project);
    }
    if (!this.selectedProject.price_packages.some(pricePackage => pricePackage.id == this.orderForm.get('package_id').value)) {
      this.orderForm.get('package_id').setValue(this.selectedProject.price_packages[0].id);
      this.orderForm.get('valid_time').setValue(this.selectedProject.price_packages[0].valid_time);
      this.orderForm.get('valid_time_unit').setValue(this.selectedProject.price_packages[0].valid_time_unit);
      this.orderForm.get('price_amount').setValue(this.selectedProject.price_packages[0].price_amount);
      this.orderForm.get('price_currency').setValue(this.selectedProject.price_packages[0].price_currency);
    }
    
    if (this.selectedProject.price_packages[0].number_of_facilities != null && !init &&
      this.selectedProject.price_packages[0].number_of_facilities != this.orderForm.get("number_of_facilities").value &&
      this.selectedProject.price_packages.some(pricePackage => pricePackage.number_of_facilities == this.orderForm.get("number_of_facilities").value)) {
      this.orderForm.get('number_of_facilities').setValue(this.selectedProject.price_packages[0].number_of_facilities);
    }
  }

  setMembershipOrganisations() {
    this.membershipOrganisations = MEMBERSHIP_ORGANISATIONS;
    this.membershipOrganisations.sort(function (a, b) {
      if (a.name < b.name) {
        return -1;
      }
      if (a.name > b.name) {
        return 1;
      }
      return 0;
    });
  }

  initializeOrderForm() {
    this.orderForm = this._fb.group({
      order_id: [''],

      project: [''],
      package_id : [''],
      valid_time : [''],
      valid_time_unit : [''],
      price_amount : [''],
      price_currency : [''],
      
      company_name: ['', Validators.required],
      number_of_facilities: ['', [Validators.pattern(/^[1-9]\d*$/), Validators.required]],
      zip: ['', [Validators.pattern(/^\d+$/), Validators.minLength(5), Validators.maxLength(5), Validators.required]],
      city: ['', Validators.required],
      street: ['', Validators.required],
      email: ['', [Validators.email, Validators.required]],
      membership_organisation: ['Sonstige'],
      membership_nr: [''],
      
      contact_name: ['', Validators.required],
      contact_surname: [''],
      contact_email: ['', [Validators.email, Validators.required]],
      contact_phone: [''],
      
      internal_ref_id: [''],

      bill_to_company_name: [''],
      bill_to_zip: [''],
      bill_to_city: [''],
      bill_to_street: [''],
      bill_to_name: [''],
      bill_to_surname: [''],
      bill_to_email: [''],
      bill_to_phone: ['']
    });
  }

  cancelOrderCreation() {
    this._activeModal.close({orderSubmitted: false});
  }

  onFacilitySameAsCompanyCheckboxChange(event: any) {
    let checkboxIsChecked = event.target.checked;
    if (checkboxIsChecked) {
      this.facilityDataAreTheSameAsCompanyData = true;
      if (!this.facilityForm) {
        this.facilityForm = this._fb.group({
          company_category: [''],
          application_delivery_email: [''],
          facility_domain_url: [''],
          carrer_site_url: ['']
        });
      }
    } else {
      this.facilityDataAreTheSameAsCompanyData = false;
    }
  }

  submitRegistration() {
    this._spinner.show();
    this.copyCompanyDetailsAndContactDataToInvoiceForm();
    // create order
    this._facilityService.register(this.orderForm.value).subscribe(res => {
      let project = this.orderForm.get("project").value;
      this.orderID = res["order_id"];
      this.orderForm.patchValue({order_id: this.orderID});
      this._facilityService.bpaBusinessLog(this.orderID, this.sessionID, "admin-tool-order-created", project).subscribe();
      // validate order
      this._facilityService.verifyRegistration(res["email"], this.orderID).subscribe(res => {
        this._facilityService.bpaBusinessLog(this.orderID, this.sessionID, "admin-tool-order-verified", project).subscribe(res => {
          if (this.facilityDataAreTheSameAsCompanyData) {
            let data = this.setFacilityDataSameAsCompanyData();
            // create facility for this order
            this._facilityService.registerFacilities(data).subscribe(res => {
              this.facilityGenerated = true;
              this.showConfigureFacilitiesQuestion = true;
              this._facilityService.bpaBusinessLog(this.orderID, this.sessionID, "admin-tool-facility-created", project).subscribe(res => {
                this._spinner.hide();
              });
            });
          } else {
            this.facilityGenerated = false;
            this.showConfigureFacilitiesQuestion = true;
            this._spinner.hide();
          }
        });
      });
    });
  }

  copyCompanyDetailsAndContactDataToInvoiceForm() {
    this.orderForm.get("bill_to_company_name").setValue(this.orderForm.get("company_name").value);
    this.orderForm.get("bill_to_zip").setValue(this.orderForm.get("zip").value);
    this.orderForm.get("bill_to_city").setValue(this.orderForm.get("city").value);
    this.orderForm.get("bill_to_street").setValue(this.orderForm.get("street").value);
    this.orderForm.get("bill_to_name").setValue(this.orderForm.get("contact_name").value);
    this.orderForm.get("bill_to_surname").setValue(this.orderForm.get("contact_surname").value);
    this.orderForm.get("bill_to_email").setValue(this.orderForm.get("email").value);
    this.orderForm.get("bill_to_phone").setValue(this.orderForm.get("contact_phone").value);
  }

  setFacilityDataSameAsCompanyData() {
    let facility = this._fb.group({
      company_name: [this.orderForm.get("company_name").value],
      zip: [this.orderForm.get("zip").value],
      city: [this.orderForm.get("city").value],
      street: [this.orderForm.get("street").value],
      justapplai_email: [this.orderForm.get("email").value],
      ...this.facilityForm.value
    });
    let data = {
      ...this.orderForm.value,
      facilities: []
    }
    data.facilities.push(facility.value);
    this.orderData = data;
    return data;
  }

  closeModalAfterSubmission(openFacilities: boolean) {
    this._activeModal.close({
      orderSubmitted: true,
      facilityGenerated: this.facilityGenerated,
      openFacilities: openFacilities,
      order: this.orderData ? this.orderData : this.orderForm.value
    });
  }

}
