import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {NgbDropdownModule} from '@ng-bootstrap/ng-bootstrap';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {AngularSvgIconModule} from 'angular-svg-icon';
import {TranslateModule} from '@ngx-translate/core';
import {DialogComponent} from './dialog.component';

@NgModule({
  declarations: [DialogComponent],
    imports: [
        CommonModule,
        NgbDropdownModule,
        AngularSvgIconModule,
        FormsModule,
        ReactiveFormsModule,
        TranslateModule,
    ],
  exports: [DialogComponent]
})
export class DialogModule {
}
