import {BehaviorSubject} from 'rxjs';

export class AbstractStore{
  private readonly _stores: any = {};
  readonly stores$ = {};
  private added = {};

  getStore(key: string) {
    return this._stores[key].getValue();
  }

  setStore(key: string, value: any) {
    if(!this.added[key])
      this.added[key] = true;
    this._stores[key].next(value);
  }

  constructor(stores){
    for (const key in stores) {
      this.setPropertyStore(key, stores[key]);
    }
  }


  setPropertyStore(key, value){
    this._stores[key] = new BehaviorSubject(value);
    const objserable = this._stores[key].asObservable();
    this.stores$[key] = objserable;
    this[`${key}$`] = objserable;
  }

  has(key){
    return !!this.added[key];
  }
}
