import {IJobsTable, IUsersTable} from "../interfaces/interfaces";
import {environment} from "../../../environments/environment";

export class Constants {

  public static colorScheme = {
    domain: ['#5223CA', '#FF2F8E']
  };

  public static matchLevelColors = {
    domain: ['#9b9b9b', '#28A745', '#DC3545', '#ffc107']
  };

  public static allColors = {
    domain: ['#5223CAFF', '#FF2F8EFF', '#28A745FF', '#27E3BF', '#DC3545FF','#808080']
  };
};

export const MEMBERSHIP_ORGANISATIONS: {name: string, project: string} [] = [
  { name: "Sonstige", project: ""},
  { name: "Diakonisches Werk Bayern der Ev.-Luth. Kirche in Bayern - Landesverband der Inneren Mission eV.", project: ""},
  { name: "Arbeiterwohlfahrt Landesverband Bayern e.V.", project: ""},
  { name: "Arbeitgeber- und Berufsverband Privater Pflege e.V. (ABVP) - Geschäftsstelle Süd", project: ""},
  { name: "Arbeitsgemeinschaft der stationären Hospize in Bayern", project: ""},
  { name: "Bayerische Dekanekonferenz Pflege", project: ""},
  { name: "Bayerische Krankenhausgesellschaft e.V.", project: ""},
  { name: "Bundesarbeitsgemeinschaft Haus-Krankenpflege e.V. Landesverband Bayern", project: ""},
  { name: "Bundesverband Ambulante Dienste und Stationäre Einrichtungen e.V. Geschäftsstelle Süd", project: ""},
  { name: "Bundesverband privater Anbieter sozialer Dienste e.V. Landesgeschäftsstelle Bayern", project: ""},
  { name: "Deutscher Berufsverband für Pflegeberufe DBfK Bayern-Mitteldeutschland e.V.", project: ""},
  { name: "Gemeinnützige Gesellschaft für soziale Dienste - DAA - mbH", project: ""},
  { name: "Katholischer Krankenhausverband in Bayern e.V.", project: ""},
  { name: "Landesarbeitsgemeinschaft Bayerischer Berufsschulen für Altenpflege und Altenpflegehilfe", project: ""},
  { name: "Landesarbeitsgemeinschaft Kinderkrankenpflege", project: ""},
  { name: "Landesarbeitsgemeinschaft Krankenpflege", project: ""},
  { name: "Medizinischer Dienst der Krankenversicherung in Bayern", project: ""},
  { name: "Verband Bayerischer Privatschulen e.V.", project: ""},
  { name: "Verband der Privatkrankenanstalten in Bayern e.V.", project: ""},
  { name: "Vereinigung der Pflegenden in Bayern", project: ""}
];

export const JOB_CATEGORIES: { name: string, value: string} [] = [
  { name: 'Altenpflege', value: 'Altenpflege' },
  { name: 'Ausbildung', value: 'Ausbildung' },
  { name: 'Heilerziehungspflege', value:'Heilerziehungspflege' },
  { name: 'Intensivpflege', value: 'Intensivpflege' },
  { name: 'Kinderpflege', value: 'Kinderpflege' },
  { name: 'Krankenpflege',value: 'Krankenpflege' },
  { name: 'Pflegeassistenz', value: 'Pflegeassistenz' },
  { name: 'Sonstige', value: 'Sonstige' }
];

export const COMPANY_CATEGORIES: { name: string, value: string} [] = [
  { name: 'Altenpflegeheime oder Seniorenheime', value: 'Altenpflegeheime oder Seniorenheime' },
  { name: 'Ambulante Dienste', value: 'Ambulante Dienste' },
  { name: 'Fachkliniken und Krankenhäuser', value: 'Fachkliniken und Krankenhäuser' },
  { name: 'Gesundheitszentren', value: 'Gesundheitszentren' },
  { name: 'Kindertagesstätten', value: 'Kindertagesstätten' },
  { name: 'Sonstige', value: 'Sonstige' },
  { name: 'Wohnheime für Menschen mit Behinderungen', value: 'Wohnheime für Menschen mit Behinderungen' }
];

export const SIMPLE_JAP_TEMPLATES: { job_category: string, template_name: string, simple_jap: number, is_custom: number, overwrite_template_data: number, primary_language: string } [] = [
  { job_category: "Altenpflege", template_name: "DBP-BPA-ALT", simple_jap: 1, is_custom: 0, overwrite_template_data: 1, primary_language: "DE" },
  { job_category: "Ausbildung", template_name: "DBP-BPA-AZU", simple_jap: 1, is_custom: 0, overwrite_template_data: 1, primary_language: "DE" },
  { job_category: 'Heilerziehungspflege', template_name: "DBP-BPA-KRA", simple_jap: 1, is_custom: 0, overwrite_template_data: 1, primary_language: "DE" },
  { job_category: "Intensivpflege", template_name: "DBP-BPA-INT", simple_jap: 1, is_custom: 0, overwrite_template_data: 1, primary_language: "DE" },
  { job_category: "Kinderpflege", template_name: "DBP-BPA-KIN", simple_jap: 1, is_custom: 0, overwrite_template_data: 1, primary_language: "DE" },
  { job_category: "Krankenpflege", template_name: "DBP-BPA-KRA", simple_jap: 1, is_custom: 0, overwrite_template_data: 1, primary_language: "DE" },
  { job_category: "Pflegeassistenz", template_name: "DBP-BPA-PAS", simple_jap: 1, is_custom: 0, overwrite_template_data: 1, primary_language: "DE" },
  { job_category: "Sonstige", template_name: "DBP-BPA-PSS", simple_jap: 1, is_custom: 0, overwrite_template_data: 1, primary_language: "DE" }
];

export const NORMAL_JAP_TEMPLATES: { job_category: string, template_name: string, simple_jap: number, is_custom: number, overwrite_template_data: number, primary_language: string } [] = [
  { job_category: "Altenpflege", template_name: "DBP-BPA-N-ALT", simple_jap: 0, is_custom: 0, overwrite_template_data: 1, primary_language: "DE" },
  { job_category: "Ausbildung", template_name: "DBP-BPA-N-AZU", simple_jap: 0, is_custom: 0, overwrite_template_data: 1, primary_language: "DE" },
  { job_category: 'Heilerziehungspflege', template_name: "DBP-BPA-N-KRA", simple_jap: 0, is_custom: 0, overwrite_template_data: 1, primary_language: "DE" },
  { job_category: "Intensivpflege", template_name: "DBP-BPA-N-INT", simple_jap: 0, is_custom: 0, overwrite_template_data: 1, primary_language: "DE" },
  { job_category: "Kinderpflege", template_name: "DBP-BPA-N-KIN", simple_jap: 0, is_custom: 0, overwrite_template_data: 1, primary_language: "DE" },
  { job_category: "Krankenpflege", template_name: "DBP-BPA-N-KRA", simple_jap: 0, is_custom: 0, overwrite_template_data: 1, primary_language: "DE" },
  { job_category: "Pflegeassistenz", template_name: "DBP-BPA-N-PAS", simple_jap: 0, is_custom: 0, overwrite_template_data: 1, primary_language: "DE" },
  { job_category: "Sonstige", template_name: "DBP-BPA-N-PSS", simple_jap: 0, is_custom: 0, overwrite_template_data: 1, primary_language: "DE" }
];

export const BENEFITS = [
  {
    company_pension: "Betriebliche Altersvorsorge",
    capital_forming : "Vermögenswirksame Leistungen",
    health_care: "Gesundheitsvorsorge",
    company_car: "Firmenwagen",
    job_ticket: "Jobticket",
    meal_subsidy: "Essenszuschuss",
    free_drinks: "Kostenfreie Getränke",
    childcare: "Kinderbetreuung",
    sports_fitness: "Sport & Fitness"
  },
  {
    further_education: "Weiterbildung",
    home_office: "Homeoffice",
    company_phone: "Firmenhandy",
    sabbaticals: "Sabbaticals",
    parking: "Parkplatz",
    barrier_free: "Barrierefreiheit",
    flexi_time :"Flexible Arbeitszeiten",
    cafeteria: "Cafeteria",
    additional_holidays: "Zusätzliche Urlaubstage"
  },
  {
    profit_bonus: "Erfolgsbonus",
    profit_sharing: "Zusätzliches Gehalt",
    free_fruit: "Kostenfreies Obst",
    corporate_card: "Firmenkreditkarte",
    employee_events: "Mitarbeiterevents",
    career_opportunities: "Karrieremöglichkeiten",
    company_laptop: "Firmenlaptop",
    company_bikes: "Company Bikes",
    fuel_card: "Tankkarte"
  },
  {
    coaching: "Coaching",
    company_doctor: "Betriebsarzt",
    car_travel: "KfZ-/Reisezulage",
    transport_connections: "Gute Verkehrsanbindung",
    voucher: "Gutscheine",
    corporate_apartment: "Firmenwohnung",
    dogs: "Hunde geduldet",
    childcare_allowance: "Betreuungszuschuss",
    glasses: "PC-Brille"
  }
];

export const JOB_TABLE_COLUMNS: IJobsTable[] = [
  {
    name: 'Type',
    key: 'job_source',
    icon: 'bi-sort-alpha-down',
    reverse_icon: 'bi-sort-alpha-down-alt',
    style: 'min-width: 90px',
    visible: true
  },
  {
    name: 'Publish Date',
    key: 'publish_date',
    icon: 'bi-sort-down',
    reverse_icon: 'bi-sort-up',
    style: 'min-width: 150px',
    omitted: true,
    visible: true
  },
  {
    name: 'Title',
    key: 'job_title',
    icon: 'bi-sort-alpha-down',
    reverse_icon: 'bi-sort-alpha-down-alt',
    style: 'min-width: 350px',
    visible: true

  },
  {
    name: 'Unternehmen',
    key: 'company_name',
    icon: 'bi-sort-alpha-down',
    reverse_icon: 'bi-sort-alpha-down-alt',
    style: 'min-width: 150px',
    visible: true
  },
  {
    name: 'Berufskategorien',
    key: 'job_category',
    icon: 'bi-sort-alpha-down',
    reverse_icon: 'bi-sort-alpha-down-alt',
    style: 'min-width: 180px',
    visible: !(!![618,619]?.includes(environment?.order_id))
  },
  {
    name: 'Unternehmenskategorien',
    key: 'company_category',
    icon: 'bi-sort-down',
    reverse_icon: 'bi-sort-up',
    style: 'min-width: 250px',
    visible: true
  },
  {
    name: 'Zip Code',
    key: 'zip',
    icon: 'bi-sort-down',
    reverse_icon: 'bi-sort-up',
    hidden: true,
    omitted: true,
    visible: true
  },

  {
    name: 'Link',
    key: 'apply_url',
    icon: 'bi-sort-down',
    reverse_icon: 'bi-sort-up',
    style: 'min-width: 70px; text-align: center',
    hidden: true,
    omitted: true,
    visible: true
  },
  {
    name: 'Justapply Link',
    key: 'justapply_url',
    icon: 'bi-sort-down',
    reverse_icon: 'bi-sort-up',
    style: 'min-width: 70px; text-align: center',
    hidden: true,
    omitted: true,
    visible: true
  },
  {
    name: 'Actions',
    key: 'actions',
    icon: 'bi-sort-down',
    reverse_icon: 'bi-sort-up',
    style: 'min-width: 100px; text-align: center',
    hidden: true,
    omitted: true,
    visible: true
  },
].filter(columns => columns.visible);


export const USERS_TABLE_COLUMNS: IUsersTable[] = [
  {
    name: 'Vorname',
    key: 'first_name',
    icon: 'bi-sort-alpha-down',
    reverse_icon: 'bi-sort-alpha-down-alt'
  },
  {
    name: 'Nachname',
    key: 'last_name',
    icon: 'bi-sort-alpha-down',
    reverse_icon: 'bi-sort-alpha-down-alt',
    style: 'min-width: 140px'
  },
  {
    name: 'E-Mail',
    key: 'email',
    icon: 'bi-sort-alpha-down',
    reverse_icon: 'bi-sort-alpha-down-alt'
  },
  {
    name: 'Unternehmen',
    key: 'company',
    detail_key: 'name',
    icon: 'bi-sort-alpha-down',
    reverse_icon: 'bi-sort-alpha-down-alt',
    style: 'min-width: 200px'
  },
  {
    name: 'Justappl.ai?',
    key: 'just_apply_email',
    detail_key:'just_apply_email',
    icon: 'bi-sort-down',
    reverse_icon: 'bi-sort-up',
    style: 'min-width: 150px',
    omitted: true,

  },
  {
    name: 'Status',
    key: 'active',
    icon: 'bi-sort-down',
    reverse_icon: 'bi-sort-up',
    style: 'min-width: 140px',
    omitted: true,
  },
  {
    name: 'Actions',
    key: 'actions',
    icon: 'bi-sort-down',
    reverse_icon: 'bi-sort-up',
    hidden: true,
    omitted: true,
  }
];

export const SYSTEM_LANGUAGES = [
  {
    id: 'en',
    name: 'EN',
    flag: ''
  },
  {
    id: 'de',
    name: 'DE',
    flag: ''
  },
  {
    id: 'de-informal',
    name: 'DE-INFORMAL',
    flag: ''
  }
];
