<div style="padding: 20px;overflow-y: auto;">
    <div *ngIf="orderCompanyName && orderIntegerId != null" class="order-subheading" [ngStyle]="!permissionForManagingOrders && {'width': '100%', 'text-align': 'center'}"><i class="bi bi-building" style="font-size: 11px;margin-right: 5px;"></i>{{orderCompanyName}} <span [ngStyle]="orderComments.length > 0 && {'color':'#e38a38'}">(<span style="text-decoration: underline;margin: 0 1px" (click)="openOrderComments()">{{orderComments.length}} Kommentar{{orderComments.length != 1 ? "e" : ""}}</span>)</span></div>
    <div class="w-100 d-flex justify-content-center">
        <h3 style="padding-left: 6px;margin-bottom: 0px;white-space: nowrap;">Standort<span *ngIf="facilities.length > 1">en</span> bearbeiten</h3>
        <div *ngIf="permissionForManagingOrders" class="w-100 d-flex justify-content-end">
            <button class="btn btn-outline-primary" style="padding: 4px 10px;height: 34px;line-height: 1.2;white-space: nowrap;" (click)="addFacilityToFacilitiesArray()">+ Standort</button>
        </div>
    </div>
    <div *ngIf="facilities.length == 0" class="no-facilities-message text-center mt-3 mb-2">
        Keine Standorte vorhanden
    </div>
    <div class="accordion mt-3" id="accordionExample">
        <div class="card" *ngFor="let facility of facilities;let i = index;">
            <div class="card-header" [id]="'heading' + i" style="padding: 0px !important;">
                <div class="d-flex justify-content-between" style="align-items: center;column-gap: 10px;">
                    <h2 class="mb-0 w-100">
                        <button class="btn btn-link btn-block text-left facility-name-button" style="border-radius: 10px;padding: 13px 0px 13px 17px !important;min-height: 76px;" [ngStyle]="facility.valid && {'color':'#0eb370'}" type="button" data-toggle="collapse" [attr.data-target]="'#collapse-' + i" aria-expanded="true" [attr.aria-controls]="'collapse-' + i">
                            <span *ngIf="!facility.get('company_name')?.value">Standort </span>
                            <span *ngIf="facility.get('company_name')?.value?.length > 0">{{facility.get("company_name").value}} </span>
                            <span>({{i+1}}/{{facilities.length}}) </span>
                            <!-- <span style="position: relative;">
                                <i *ngIf="facility.valid" class="bi bi-check-circle"></i>
                            </span> -->
                            <div class="w-100 d-flex">
                                <!-- <div *ngIf="facility.get('id').value != null" style="font-size: 15px;margin-right: 6px;cursor: pointer;color: #6c757d;" (click)="openFacilityComments(facility)" [ngStyle]="facilitiesCommentsDict[facility.get('id').value].length > 0  && {'color': '#e38a38'}"><i class="bi bi-chat-text" style="margin-right: 5px;"></i>{{facilitiesCommentsDict[facility.get('id').value].length}} Kommentar{{facilitiesCommentsDict[facility.get('id').value].length != 1 ? "e" : ""}}</div> -->
                                <div *ngIf="facility.get('carrer_site_missing').value || facility.get('gdpr_data_missing').value || facility.get('facility_domain_missing').value" class="d-flex" style="column-gap: 6px;align-items: center;">
                                    <div style="color: #757d86;font-size: 15px;line-height: 1.1;">Fehlt:</div>
                                    <div *ngIf="facility.get('carrer_site_missing').value" class="form-check-label badge badge-light">Karriereseite</div>
                                    <div *ngIf="facility.get('gdpr_data_missing').value" class="form-check-label badge badge-light">DSGVO-Link</div>
                                    <div *ngIf="facility.get('facility_domain_missing').value" class="form-check-label badge badge-light">Domain</div>
                                </div>
                            </div>
                        </button>
                    </h2>
                    <div style="padding: 13px 17px 13px 0;">
                        <div class="custom-control custom-switch" [class.disabled-button]="!facility.valid">
                            <input [disabled]="!facility.valid" type="checkbox" class="custom-control-input" [id]="'customSwitch-' + i" [checked]="facility.get('facility_completed').value == 1" (change)="toggleOnClick(i, 'facility_completed')">
                            <label class="custom-control-label" [for]="'customSwitch-' + i">Standort vollständig?</label>
                        </div>
                        <div class="d-flex justify-content-end">
                            <div *ngIf="facility.get('id').value != null" class="comments" (click)="openFacilityComments(facility)" [ngStyle]="facilitiesCommentsDict[facility.get('id').value].length > 0  && {'color': '#e38a38'}"><i class="bi bi-chat-text" style="margin-right: 5px;"></i>{{facilitiesCommentsDict[facility.get('id').value].length}} Kommentar{{facilitiesCommentsDict[facility.get('id').value].length != 1 ? "e" : ""}}</div>
                        </div>
                    </div>
                </div>
            </div>
      
          <div [id]="'collapse-' + i" #collapseElements class="collapse" [attr.aria-labelledby]="'heading' + i" data-parent="#accordionExample">

                <div class="card-header" style="padding-top: 0px;">
                  <ul class="nav nav-tabs card-header-tabs" style="margin-top: -6px;">
                    <li class="nav-item">
                      <a class="nav-link" [class.active]="selectedFacilityTab == 'Stammdaten'" [ngStyle]="facilityMainDataAreInvalid(facility) && {'color':'red'}" (click)="selectedFacilityTab = 'Stammdaten'">Stammdaten</a>
                    </li>
                    <li class="nav-item">
                      <a class="nav-link" [class.active]="selectedFacilityTab == 'Zusatzinfos'" [ngStyle]="logoImageUrlIsInvalid(facility) && {'color':'red'}" (click)="selectedFacilityTab = 'Zusatzinfos'">Zusatzinfos</a>
                    </li>
                    <li class="nav-item">
                      <a class="nav-link" [class.active]="selectedFacilityTab == 'Benefits'" (click)="selectedFacilityTab = 'Benefits'">Benefits</a>
                    </li>
                    <li class="nav-item">
                      <a class="nav-link" [class.active]="selectedFacilityTab == 'Chat-Medien'" [ngStyle]="(imageUrlsAreInvalid(facility) || videoUrlsAreInvalid(facility)) && {'color':'red'}" (click)="selectedFacilityTab = 'Chat-Medien'">Chat-Medien</a>
                    </li>
                    <li class="nav-item" *ngIf="permissionForMissingValues">
                      <a class="nav-link" [class.active]="selectedFacilityTab == 'justappl.ai'" [ngStyle]="(facility.get('justapplai_email').errors?.email || !facility.get('justapplai_email').value) && {'color':'red'}" (click)="selectJapTab()">justappl.ai</a>
                    </li>
                  </ul>
                </div>
                
            <div class="card-body text-left">
                <form [formGroup]="facility">
                    <!-- FACILITY MAIN DATA -->
                    <!-- STAMMDATEN -->
                    <div *ngIf="selectedFacilityTab == 'Stammdaten'">
                        <div class="form-row">
                            <div class="form-group col-md-6">
                                <label for="inputAddress">Unternehmensname<span class="required-form-field">*</span></label>
                                <input type="text" formControlName="company_name" class="form-control" id="inputAddress" placeholder="Unternehmensname">
                            </div>
                            <div class="form-group col-md-6">
                                <label for="inputPassword4">Unternehmenskategorie<span class="required-form-field">*</span></label>
                                <select formControlName="company_category" id="inputState" class="form-control" [ngStyle]="!facility.get('company_category').value && {'color': 'red'}">
                                    <option value="" disabled selected hidden>Wählen Sie eine Kategorie aus...</option>
                                    <option [selected]="facility.get('company_category').value === category.name" *ngFor="let category of companyCategories">{{category.name}}</option>
                                </select>
                            </div>
                        </div>
                        <div class="form-row">
                            <div class="form-group col-md-3">
                                <label for="inputEmail4">Postleitzahl<span class="required-form-field">*</span></label>
                                <input type="text" formControlName="zip" class="form-control" id="inputEmail4" placeholder="Postleitzahl">
                                <small class="text-danger" *ngIf="facility.get('zip').value && facility.get('zip').errors">
                                    Die Postleitzahl muss fünfstellig sein.
                                </small>
                            </div>
                            <div class="form-group col-md-4">
                                <label for="inputEmail4">Stadt<span class="required-form-field">*</span></label>
                                <input type="text" formControlName="city" class="form-control" id="inputEmail4" placeholder="Stadt">
                            </div>
                            <div class="form-group col-md-5">
                                <label for="inputPassword4">Straße & Hausnummer<span class="required-form-field">*</span></label>
                                <input type="text" formControlName="street" class="form-control" id="inputPassword4" placeholder="Straße & Hausnummer">
                            </div>
                        </div>
                        <label for="inputEmail4">E-Mails zur Auslieferung der Bewerber<span class="required-form-field">*</span></label>
                        <form class="form-row">
                            <div class="form-group col-md-6 position-relative" *ngFor="let email of applicationDeliveryEmails[i];let j = index; trackBy: trackByFn" style="margin-bottom: 7px;">
                                <input type="text" [(ngModel)]="applicationDeliveryEmails[i][j]" (ngModelChange)="onApplicationDeliveryEmailChange($event)" name="email-{{j}}" class="form-control" id="inputEmail4" placeholder="E-Mail" style="padding-right: 24px;">
                                <i *ngIf="applicationDeliveryEmails[i].length > 1" class="bi bi-trash delete-email-input" (click)="deleteApplicationDeliveryEmail(i, j)"></i>
                            </div>
                            <button class="btn btn-light another-email-btn" (click)="applicationDeliveryEmails[i].push('')"><i class="bi bi-plus-lg"></i></button>
                        </form>
                        <div class="form-row">
                            <div class="form-group col-md-6">
                                <div class="d-flex label-with-buttons">
                                    <label for="inputEmail4">
                                        Karriereseite
                                    </label>
                                    <div class="d-flex" style="align-items: center;">
                                        <div style="white-space: nowrap;" class="text-muted">Fehlt?</div>
                                        <label class="switch ml-1">
                                            <input type="checkbox" (change)="toggleOnClick(i, 'carrer_site_missing')"
                                            [checked]="facility.get('carrer_site_missing').value == 1">
                                            <span class="slider round"></span>
                                        </label>
                                    </div>
                                    <div *ngIf="facilities?.length > 1" class="dropdown facility-property-dropdown">
                                        <div type="button" data-toggle="dropdown">
                                            <span><i class="bi bi-three-dots-vertical"></i></span>
                                        </div>
                                        <ul class="dropdown-menu">
                                            <li (click)="applyValueForAllFacilities('carrer_site_url', i, 'carrer_site_missing')">
                                                <span class="dropdown-label">Karriereseiten-Link</span>
                                                <span>für alle Standorte übernehmen</span>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <input type="text" formControlName="carrer_site_url" class="form-control" id="inputEmail4" placeholder="Link">
                                <small class="text-danger" *ngIf="facility.get('carrer_site_url').value && facility.get('carrer_site_url').errors">Der Link ist ungültig.</small>
                            </div>
                            <div class="form-group col-md-6">
                                <div class="d-flex label-with-buttons">
                                    <label for="inputEmail4">
                                        Domain (Website)
                                    </label>
                                    <div class="d-flex" style="align-items: center;">
                                        <div style="white-space: nowrap;" class="text-muted">Fehlt?</div>
                                        <label class="switch ml-1">
                                            <input type="checkbox" (change)="toggleOnClick(i, 'facility_domain_missing')"
                                            [checked]="facility.get('facility_domain_missing').value == 1">
                                            <span class="slider round"></span>
                                        </label>
                                    </div>
                                    <div *ngIf="facilities?.length > 1" class="dropdown facility-property-dropdown">
                                        <div type="button" data-toggle="dropdown">
                                            <span><i class="bi bi-three-dots-vertical"></i></span>
                                        </div>
                                        <ul class="dropdown-menu">
                                            <li (click)="applyValueForAllFacilities('facility_domain_url', i, 'facility_domain_missing')">
                                                <span class="dropdown-label">Domain (Website)</span>
                                                <span>für alle Standorte übernehmen</span>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <input type="text" formControlName="facility_domain_url" class="form-control" id="inputEmail4" placeholder="Link">
                                <small class="text-danger" *ngIf="facility.get('facility_domain_url').value && facility.get('facility_domain_url').errors">Der Link ist ungültig.</small>
                            </div>
                        </div>
                        <div *ngIf="permissionForManagingOrders" class="w-100 d-flex justify-content-between mt-1">
                            <div class="btn btn-outline-danger" style="height: 31px;font-size: 14px !important;padding: 4px 10px;" (click)="openFacilityDeleteModal(facility, i)">Standort löschen</div>
                            
                            <div style="display: flex;flex-direction: column;justify-content: center;font-size: 14px;text-align: end;color: #6c757dd9;">
                                <!-- Demo links not yet sent -->
                                <div *ngIf="facility.get('facility_completed').value && !facility.get('demo_links_sent_at').value">
                                    <div>Es wurden noch keine Demo-Links versendet.</div>
                                </div>

                                <!-- Demo links sent and rejected or not confirmed -->
                                <div *ngIf="facility.get('demo_links_sent_at').value && !facility.get('demo_links_confirmed_at').value">
                                    <div class="btn btn-outline-success mb-1" style="font-size: 14px !important;padding: 4px 10px;"
                                            (click)="enableValidationForFacility(facility, i)">Für die Validierung freigeben</div>
                                    <div>
                                        <div *ngIf="facility.get('demo_links_sent_at').value && !facility.get('demo_links_reminder_sent_at').value && !facility.get('demo_links_rejected_at').value"><i class="bi bi-envelope mr-1"></i>Demo-Links versendet: {{facility.get('demo_links_sent_at').value}}</div>
                                        <div *ngIf="facility.get('demo_links_reminder_sent_at').value && !facility.get('demo_links_rejected_at').value"><i class="bi bi-envelope mr-1"></i>Demo-Links Reminder versendet: {{facility.get('demo_links_reminder_sent_at').value}}</div>
                                        <div *ngIf="facility.get('demo_links_rejected_at').value"><i class="bi bi-exclamation-diamond mr-1 text-danger"></i>Demo-Links abgelehnt: {{facility.get('demo_links_rejected_at').value}}</div>  
                                    </div>
                                </div>
    
                                <!-- Demo links confirmed -->
                                <div *ngIf="facility.get('demo_links_confirmed_at').value && !facility.get('facility_live_at').value" style="font-size: 13px;line-height: 17px;">
                                    <div>Für die Validierung freigegeben durch:</div>
                                    <div><span style="text-transform: capitalize;color: #5223caba;">{{facility.get('demo_links_confirmed_by').value}}</span> ({{facility.get('demo_links_confirmed_at').value}})</div>
                                </div>

                                <!-- Facility is live -->
                                <div *ngIf="facility.get('facility_live_at').value"><span style="color: #0eb370;">Live seit: </span>{{facility.get('facility_live_at').value}}</div>
                            </div>
                        </div>
                    </div>
                    <!-- ZUSATZINFOS -->
                    <div *ngIf="selectedFacilityTab == 'Zusatzinfos'">
                        <div class="form-row">
                            <div class="form-group col-md-6">
                                <div class="d-flex">
                                    <label for="inputEmail4">Recruiting Space Code</label>
                                    <div *ngIf="facilities?.length > 1" class="dropdown facility-property-dropdown">
                                        <div type="button" data-toggle="dropdown">
                                            <span><i class="bi bi-three-dots-vertical"></i></span>
                                        </div>
                                        <ul class="dropdown-menu">
                                            <li (click)="applyValueForAllFacilities('recruiting_space_code', i)">
                                                <span class="dropdown-label">Recruiting Space Code</span>
                                                <span>für alle Standorte übernehmen</span>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <input type="text" formControlName="recruiting_space_code" class="form-control" id="inputEmail4" placeholder="Recruiting Space Code">
                            </div>
                            <div class="form-group col-md-6">
                                <div class="d-flex">
                                    <label for="inputEmail4">DSGVO-Unternehmensname</label>
                                    <div *ngIf="facilities?.length > 1" class="dropdown facility-property-dropdown">
                                        <div type="button" data-toggle="dropdown">
                                            <span><i class="bi bi-three-dots-vertical"></i></span>
                                        </div>
                                        <ul class="dropdown-menu">
                                            <li (click)="applyValueForAllFacilities('gdpr_company_name', i)">
                                                <span class="dropdown-label">DSGVO-Unternehmensname</span>
                                                <span>für alle Standorte übernehmen</span>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <input type="text" formControlName="gdpr_company_name" class="form-control" id="inputEmail4" placeholder="DSGVO-Unternehmensname">
                            </div>
                        </div>
                        <div class="form-row">
                            <div class="form-group col-md-12">
                                <div class="d-flex label-with-buttons">
                                    <label for="inputEmail4">DSGVO-Link</label>
                                    <div class="d-flex" style="align-items: center;">
                                        <div style="white-space: nowrap;" class="text-muted">Fehlt?</div>
                                        <label class="switch ml-1">
                                            <input type="checkbox" (change)="toggleOnClick(i, 'gdpr_data_missing')"
                                            [checked]="facility.get('gdpr_data_missing').value == 1">
                                            <span class="slider round"></span>
                                        </label>
                                    </div>
                                    <div *ngIf="facilities?.length > 1" class="dropdown facility-property-dropdown">
                                        <div type="button" data-toggle="dropdown">
                                            <span><i class="bi bi-three-dots-vertical"></i></span>
                                        </div>
                                        <ul class="dropdown-menu">
                                            <li (click)="applyValueForAllFacilities('gdpr_link', i, 'gdpr_data_missing')">
                                                <span class="dropdown-label">DSGVO-Link</span>
                                                <span>für alle Standorte übernehmen</span>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <input type="text" formControlName="gdpr_link" class="form-control" id="inputEmail4" placeholder="Link">
                                <small class="text-danger" *ngIf="facility.get('gdpr_link').value && facility.get('gdpr_link').errors">Der Link ist ungültig.</small>
                            </div>
                        </div>
                        <div class="form-row">
                            <div class="form-group col-md-6">
                                <div class="d-flex">
                                    <label for="inputEmail4">Logo-Link</label>
                                    <div *ngIf="facilities?.length > 1" class="dropdown facility-property-dropdown">
                                        <div type="button" data-toggle="dropdown">
                                            <span><i class="bi bi-three-dots-vertical"></i></span>
                                        </div>
                                        <ul class="dropdown-menu">
                                            <li (click)="applyValueForAllFacilities('facility_logo', i)">
                                                <span class="dropdown-label">Logo-Link</span>
                                                <span>für alle Standorte übernehmen</span>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <input type="text" formControlName="facility_logo" class="form-control" id="inputEmail4" placeholder="Bild-Link">
                                <small class="text-danger" *ngIf="facility.get('facility_logo').value && facility.get('facility_logo').errors">Der Link ist kein Bild.</small>
                            </div>
                            <div class="form-group col-md-6">
                                <div *ngIf="facility.get('facility_logo').errors || !facility.get('facility_logo').value" class="d-flex">
                                    <span style="margin-top: 34px;margin-right: 10px">ODER</span>
                                    <div id="upload-btn">
                                        <input type="file"
                                               class="d-none"
                                               id="file"
                                               accept="image/jpeg,image/jpg,image/png"
                                               #fileUpload7
                                               (change)="handleFileInput($event.target.files, i, 7)">
                                        <button class="btn btn-light img-upload-btn" type="button" (click)="fileUpload7.click()"><i class="bi bi-cloud-upload mr-2"></i>Bild hochladen</button>
                                    </div>
                                </div>
                                <div *ngIf="facility.get('facility_logo').value && !facility.get('facility_logo').errors">
                                    <button class="btn btn-light img-upload-btn" type="button" (click)="deleteImage(i, 'facility_logo')"><i class="bi bi-trash mr-2"></i>Bild löschen</button>
                                    <button class="btn btn-light img-upload-btn" type="button" (click)="openLinkInNewTab(facility.get('facility_logo').value)" style="margin-left: 10px;"><i class="bi bi-eye mr-2"></i>Vorschau</button>
                                </div>
                            </div>
                        </div>
                        <div class="form-row">
                            <div class="form-group col-md-12">
                                <div class="d-flex">
                                    <label>Beschreibung der Standort</label>
                                    <div *ngIf="facilities?.length > 1" class="dropdown facility-property-dropdown">
                                        <div type="button" data-toggle="dropdown">
                                            <span><i class="bi bi-three-dots-vertical"></i></span>
                                        </div>
                                        <ul class="dropdown-menu">
                                            <li (click)="applyValueForAllFacilities('facility_description', i)">
                                                <span class="dropdown-label">Beschreibung der Standort</span>
                                                <span>für alle Standorte übernehmen</span>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <textarea formControlName="facility_description" class="form-control" rows="8" style="width: 100%;"></textarea>
                            </div>
                        </div>
                    </div>
                    <!-- BENEFITS -->
                    <div *ngIf="selectedFacilityTab == 'Benefits'">
                        <div class="form-row">
                            <div class="form-group col-md-12">
                                <div class="d-flex">
                                    <label>Benefits <span [ngStyle]="(facility.get('benefits')?.value?.length == 10) && {'color':'red'}">{{facility.get('benefits')?.value?.length}}</span>/9</label>
                                    <div *ngIf="facilities?.length > 1" class="dropdown facility-property-dropdown">
                                        <div type="button" data-toggle="dropdown">
                                            <span><i class="bi bi-three-dots-vertical"></i></span>
                                        </div>
                                        <ul class="dropdown-menu">
                                            <li (click)="applyValueForAllFacilities('benefits', i)">
                                                <span class="dropdown-label">Benefits</span>
                                                <span>für alle Standorte übernehmen</span>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <ngb-carousel class="job-edit-carousel"
                                            [interval]="-1"
                                            (slide)="slideChange($event)"
                                            [activeId]="currentSlideIndex">
                                    <ng-template ngbSlide>
                                        <div class="row">
                                            <div class="col-4 text-center my-2 px-2" *ngFor="let benefit of benefits[0] | keyvalue">
                                                <div class="bg-light p-2" style="height: 100%;">
                                                    <img src="assets/icons/benefits/{{benefit.key}}.png">
                                                    <p class="m-0 pt-2 benefits-p text-muted">{{benefit.value}}</p>
                                                    <label class="switch mt-3">
                                                        <input type="checkbox" (change)="benefitChanged(benefit.key, i)"
                                                                [checked]="benefitChecked(benefit.key, i)">
                                                        <span class="slider round"></span>
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </ng-template>
                                    <ng-template ngbSlide>
                                        <div class="row">
                                            <div class="col-4 text-center my-2 px-2" *ngFor="let benefit of benefits[1] | keyvalue">
                                                <div class="bg-light p-2" style="height: 100%;">
                                                    <img src="assets/icons/benefits/{{benefit.key}}.png">
                                                    <p class="m-0 pt-2 benefits-p text-muted">{{benefit.value}}</p>
                                                    <label class="switch mt-3">
                                                        <input type="checkbox" (change)="benefitChanged(benefit.key, i)"
                                                                [checked]="benefitChecked(benefit.key, i)">
                                                        <span class="slider round"></span>
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </ng-template>
                                    <ng-template ngbSlide>
                                        <div class="row">
                                            <div class="col-4 text-center my-2 px-2" *ngFor="let benefit of benefits[2] | keyvalue">
                                                <div class="bg-light p-2" style="height: 100%;">
                                                    <img src="assets/icons/benefits/{{benefit.key}}.png">
                                                    <p class="m-0 pt-2 benefits-p text-muted">{{benefit.value}}</p>
                                                    <label class="switch mt-3">
                                                        <input type="checkbox" (change)="benefitChanged(benefit.key, i)"
                                                                [checked]="benefitChecked(benefit.key, i)">
                                                        <span class="slider round"></span>
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </ng-template>
                                    <ng-template ngbSlide>
                                        <div class="row">
                                            <div class="col-4 text-center my-2 px-2" *ngFor="let benefit of benefits[3] | keyvalue">
                                                <div class="bg-light p-2" style="height: 100%">
                                                    <img src="assets/icons/benefits/{{benefit.key}}.png">
                                                    <p class="m-0 pt-2 benefits-p text-muted">{{benefit.value}}</p>
                                                    <label class="switch mt-3">
                                                        <input type="checkbox" (change)="benefitChanged(benefit.key, i)"
                                                                [checked]="benefitChecked(benefit.key, i)">
                                                        <span class="slider round"></span>
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </ng-template>
                                </ngb-carousel>
                            </div>
                        </div>
                    </div>
                    <!-- CHAT MEDIA -->
                    <div *ngIf="selectedFacilityTab == 'Chat-Medien'">
                        <!-- QUESTION 1 -->
                        <div class="question-wrapper">
                            <div class="text-muted mb-2 text-nowrap">Frage 1:</div>
                            <div class="form-row w-100">
                                <div class="form-group col-md-6">
                                    <div class="d-flex">
                                        <label><i class="bi bi-card-image mr-2"></i>Bild (Team)</label>
                                        <div *ngIf="facilities?.length > 1" class="dropdown facility-property-dropdown">
                                            <div type="button" data-toggle="dropdown">
                                                <span><i class="bi bi-three-dots-vertical"></i></span>
                                            </div>
                                            <ul class="dropdown-menu">
                                                <li (click)="applyValueForAllFacilities('q1_image_url', i)">
                                                    <span class="dropdown-label">Bild (Frage 1)</span>
                                                    <span>für alle Standorte übernehmen</span>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <input type="text" formControlName="q1_image_url" class="form-control" id="inputEmail4" placeholder="Bild-Link">
                                    <small class="text-danger" *ngIf="facility.get('q1_image_url').errors">Der Link ist kein Bild.</small>
                                </div>
                                <div class="form-group col-md-6">
                                    <div *ngIf="facility.get('q1_image_url').errors || !facility.get('q1_image_url').value" class="d-flex">
                                        <span style="margin-top: 34px;margin-right: 10px">ODER</span>
                                        <div id="upload-btn">
                                            <input type="file"
                                                   class="d-none"
                                                   id="file"
                                                   accept="image/jpeg,image/jpg,image/png"
                                                   #fileUpload1
                                                   (change)="handleFileInput($event.target.files, i, 1)">
                                            <button class="btn btn-light img-upload-btn" type="button" (click)="fileUpload1.click()"><i class="bi bi-cloud-upload mr-2"></i>Bild hochladen</button>
                                        </div>
                                    </div>
                                    <div *ngIf="!facility.get('q1_image_url').errors && facility.get('q1_image_url').value">
                                        <button class="btn btn-light img-upload-btn" type="button" (click)="deleteImage(i, 'q1_image_url')"><i class="bi bi-trash mr-2"></i>Bild löschen</button>
                                        <button class="btn btn-light img-upload-btn" type="button" (click)="openLinkInNewTab(facility.get('q1_image_url').value)" style="margin-left: 10px;"><i class="bi bi-eye mr-2"></i>Vorschau</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- QUESTION 2 -->
                        <div class="question-wrapper">
                            <div class="text-muted mb-2 text-nowrap">Frage 2:</div>
                            <div class="form-row w-100">
                                <div class="form-group col-md-6">
                                    <div class="d-flex">
                                        <label><i class="bi bi-card-image mr-2"></i>Bild (Team)</label>
                                        <div *ngIf="facilities?.length > 1" class="dropdown facility-property-dropdown">
                                            <div type="button" data-toggle="dropdown">
                                                <span><i class="bi bi-three-dots-vertical"></i></span>
                                            </div>
                                            <ul class="dropdown-menu">
                                                <li (click)="applyValueForAllFacilities('q2_image_url', i)">
                                                    <span class="dropdown-label">Bild (Frage 2)</span>
                                                    <span>für alle Standorte übernehmen</span>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <input type="text" formControlName="q2_image_url" class="form-control" id="inputEmail4" placeholder="Bild-Link">
                                    <small class="text-danger" *ngIf="facility.get('q2_image_url').errors">Der Link ist ungültig.</small>
                                </div>
                                <div class="form-group col-md-6">
                                    <div *ngIf="facility.get('q2_image_url').errors || !facility.get('q2_image_url').value" class="d-flex">
                                        <span style="margin-top: 34px;margin-right: 10px">ODER</span>
                                        <div id="upload-btn">
                                            <input type="file"
                                                   class="d-none"
                                                   id="file"
                                                   accept="image/jpeg,image/jpg,image/png"
                                                   #fileUpload2
                                                   (change)="handleFileInput($event.target.files, i, 2)">
                                            <button class="btn btn-light img-upload-btn" type="button" (click)="fileUpload2.click()"><i class="bi bi-cloud-upload mr-2"></i>Bild hochladen</button>
                                        </div>
                                    </div>
                                    <div *ngIf="!facility.get('q2_image_url').errors && facility.get('q2_image_url').value">
                                        <button class="btn btn-light img-upload-btn" type="button" (click)="deleteImage(i, 'q2_image_url')"><i class="bi bi-trash mr-2"></i>Bild löschen</button>
                                        <button class="btn btn-light img-upload-btn" type="button" (click)="openLinkInNewTab(facility.get('q2_image_url').value)" style="margin-left: 10px;"><i class="bi bi-eye mr-2"></i>Vorschau</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- QUESTION 3 is the same as question 2 -->
                        <!-- QUESTION 4 -->
                        <div>
                            <div class="question-wrapper">
                                <div class="text-muted mb-2 text-nowrap">Frage 3:</div>
                                <div class="form-row w-100">
                                    <div class="form-group col-md-6">
                                        <div class="d-flex">
                                            <label><i class="bi bi-card-image mr-2"></i>Bild (Job)</label>
                                            <div *ngIf="facilities?.length > 1" class="dropdown facility-property-dropdown">
                                                <div type="button" data-toggle="dropdown">
                                                    <span><i class="bi bi-three-dots-vertical"></i></span>
                                                </div>
                                                <ul class="dropdown-menu">
                                                    <li (click)="applyValueForAllFacilities('q4_image_url', i)">
                                                        <span class="dropdown-label">Bild (Frage 3)</span>
                                                    <span>für alle Standorte übernehmen</span>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                        <input type="text" formControlName="q4_image_url" class="form-control" id="inputEmail4" placeholder="Bild-Link">
                                        <small class="text-danger" *ngIf="facility.get('q4_image_url').errors">Der Link ist ungültig.</small>
                                    </div>
                                    <div class="form-group col-md-6">
                                        <div *ngIf="facility.get('q4_image_url').errors || !facility.get('q4_image_url').value" class="d-flex">
                                            <span style="margin-top: 34px;margin-right: 10px">ODER</span>
                                            <div id="upload-btn">
                                                <input type="file"
                                                       class="d-none"
                                                       id="file"
                                                       accept="image/jpeg,image/jpg,image/png"
                                                       #fileUpload4
                                                       (change)="handleFileInput($event.target.files, i, 4)">
                                                <button class="btn btn-light img-upload-btn" type="button" (click)="fileUpload4.click()"><i class="bi bi-cloud-upload mr-2"></i>Bild hochladen</button>
                                            </div>
                                        </div>
                                        <div *ngIf="!facility.get('q4_image_url').errors && facility.get('q4_image_url').value">
                                            <button class="btn btn-light img-upload-btn" type="button" (click)="deleteImage(i, 'q4_image_url')"><i class="bi bi-trash mr-2"></i>Bild löschen</button>
                                            <button class="btn btn-light img-upload-btn" type="button" (click)="openLinkInNewTab(facility.get('q4_image_url').value)" style="margin-left: 10px;"><i class="bi bi-eye mr-2"></i>Vorschau</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="form-row w-100" style="padding-left: 76px;">
                                <div class="form-group" style="padding-right: 2px;">
                                    <div class="d-flex">
                                        <label><i class="bi bi-camera-reels mr-2"></i>Video - YouTube oder Vimeo (zur Ausbildung)</label>
                                        <div *ngIf="facilities?.length > 1" class="dropdown facility-property-dropdown">
                                            <div type="button" data-toggle="dropdown">
                                                <span><i class="bi bi-three-dots-vertical"></i></span>
                                            </div>
                                            <ul class="dropdown-menu">
                                                <li (click)="applyValueForAllFacilities('q4_video_url', i)">
                                                    <span class="dropdown-label">Video (Frage 3)</span>
                                                    <span>für alle Standorte übernehmen</span>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <input type="text" formControlName="q4_video_url" class="form-control" id="inputEmail4" placeholder="Video-Link" style="width: 528px;">
                                    <small class="text-danger" *ngIf="facility.get('q4_video_url').value && facility.get('q4_video_url').errors">Der Link ist ungültig.</small>
                                </div>
                            </div>
                        </div>
                        <!-- QUESTION 5 -->
                        <div class="question-wrapper">
                            <div class="text-muted mb-2 text-nowrap">Frage 4:</div>
                            <div class="form-row w-100">
                                <div class="form-group col-md-6">
                                    <div class="d-flex">
                                        <label><i class="bi bi-card-image mr-2"></i>Bild (Job)</label>
                                        <div *ngIf="facilities?.length > 1" class="dropdown facility-property-dropdown">
                                            <div type="button" data-toggle="dropdown">
                                                <span><i class="bi bi-three-dots-vertical"></i></span>
                                            </div>
                                            <ul class="dropdown-menu">
                                                <li (click)="applyValueForAllFacilities('q5_image_url', i)">
                                                    <span class="dropdown-label">Bild (Frage 4)</span>
                                                    <span>für alle Standorte übernehmen</span>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <input type="text" formControlName="q5_image_url" class="form-control" id="inputEmail4" placeholder="Bild-Link">
                                    <small class="text-danger" *ngIf="facility.get('q5_image_url').errors">Der Link ist ungültig.</small>
                                </div>
                                <div class="form-group col-md-6">
                                    <div *ngIf="facility.get('q5_image_url').errors || !facility.get('q5_image_url').value" class="d-flex">
                                        <span style="margin-top: 34px;margin-right: 10px">ODER</span>
                                        <div id="upload-btn">
                                            <input type="file"
                                                   class="d-none"
                                                   id="file"
                                                   accept="image/jpeg,image/jpg,image/png"
                                                   #fileUpload5
                                                   (change)="handleFileInput($event.target.files, i, 5)">
                                            <button class="btn btn-light img-upload-btn" type="button" (click)="fileUpload5.click()"><i class="bi bi-cloud-upload mr-2"></i>Bild hochladen</button>
                                        </div>
                                    </div>
                                    <div *ngIf="!facility.get('q5_image_url').errors && facility.get('q5_image_url').value">
                                        <button class="btn btn-light img-upload-btn" type="button" (click)="deleteImage(i, 'q5_image_url')"><i class="bi bi-trash mr-2"></i>Bild löschen</button>
                                        <button class="btn btn-light img-upload-btn" type="button" (click)="openLinkInNewTab(facility.get('q5_image_url').value)" style="margin-left: 10px;"><i class="bi bi-eye mr-2"></i>Vorschau</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- QUESTION 6 -->
                        <div>
                            <div class="question-wrapper">
                                <div class="text-muted mb-2 text-nowrap">Frage 5:</div>
                                <div class="form-row w-100">
                                    <div class="form-group col-md-6">
                                        <div class="d-flex">
                                            <label><i class="bi bi-card-image mr-2"></i>Bild (Standort)</label>
                                            <div *ngIf="facilities?.length > 1" class="dropdown facility-property-dropdown">
                                                <div type="button" data-toggle="dropdown">
                                                    <span><i class="bi bi-three-dots-vertical"></i></span>
                                                </div>
                                                <ul class="dropdown-menu">
                                                    <li (click)="applyValueForAllFacilities('q6_image_url', i)">
                                                        <span class="dropdown-label">Bild (Frage 5)</span>
                                                        <span>für alle Standorte übernehmen</span>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                        <input type="text" formControlName="q6_image_url" class="form-control" id="inputEmail4" placeholder="Bild-Link">
                                        <small class="text-danger" *ngIf="facility.get('q6_image_url').errors">Der Link ist ungültig.</small>
                                    </div>
                                    <div class="form-group col-md-6">
                                        <div *ngIf="facility.get('q6_image_url').errors || !facility.get('q6_image_url').value" class="d-flex">
                                            <span style="margin-top: 34px;margin-right: 10px">ODER</span>
                                            <div id="upload-btn">
                                                <input type="file"
                                                       class="d-none"
                                                       id="file"
                                                       accept="image/jpeg,image/jpg,image/png"
                                                       #fileUpload6
                                                       (change)="handleFileInput($event.target.files, i, 6)">
                                                <button class="btn btn-light img-upload-btn" type="button" (click)="fileUpload6.click()"><i class="bi bi-cloud-upload mr-2"></i>Bild hochladen</button>
                                            </div>
                                        </div>
                                        <div *ngIf="!facility.get('q6_image_url').errors && facility.get('q6_image_url').value">
                                            <button class="btn btn-light img-upload-btn" type="button" (click)="deleteImage(i, 'q6_image_url')"><i class="bi bi-trash mr-2"></i>Bild löschen</button>
                                            <button class="btn btn-light img-upload-btn" type="button" (click)="openLinkInNewTab(facility.get('q6_image_url').value)" style="margin-left: 10px;"><i class="bi bi-eye mr-2"></i>Vorschau</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="form-row w-100" style="padding-left: 76px;">
                                <div class="form-group" style="padding-right: 2px;">
                                    <div class="d-flex">
                                        <label><i class="bi bi-camera-reels mr-2"></i>Video - YouTube oder Vimeo (Standort generell, z.B. Imagevideo)</label>
                                        <div *ngIf="facilities?.length > 1" class="dropdown facility-property-dropdown">
                                            <div type="button" data-toggle="dropdown">
                                                <span><i class="bi bi-three-dots-vertical"></i></span>
                                            </div>
                                            <ul class="dropdown-menu">
                                                <li (click)="applyValueForAllFacilities('q6_video_url', i)">
                                                    <span class="dropdown-label">Video (Frage 5)</span>
                                                    <span>für alle Standorte übernehmen</span>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <input type="text" formControlName="q6_video_url" class="form-control" id="inputEmail4" placeholder="Video-Link" style="width: 528px;">
                                    <small class="text-danger" *ngIf="facility.get('q6_video_url').value && facility.get('q6_video_url').errors">Der Link ist ungültig.</small>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- JUSTAPPL.AI -->
                    <div *ngIf="selectedFacilityTab == 'justappl.ai'">
                        <label for="inputEmail4">justappl.ai Account E-Mails<span class="required-form-field">*</span></label>
                        <form class="form-row">
                            <div class="form-group col-md-6 position-relative" *ngFor="let email of justapplaiEmails[i];let j = index; trackBy: trackByFn" style="margin-bottom: 7px;">
                                <input type="text" [(ngModel)]="justapplaiEmails[i][j]" (ngModelChange)="onJustapplaiEmailChange($event)" name="email-{{j}}" class="form-control" id="inputEmail4" placeholder="E-Mail" style="padding-right: 24px;">
                                <i *ngIf="justapplaiEmails[i].length > 1" class="bi bi-trash delete-email-input" (click)="deleteJustapplaiEmail(i, j)"></i>
                            </div>
                            <button class="btn btn-light another-email-btn" (click)="justapplaiEmails[i].push('')"><i class="bi bi-plus-lg"></i></button>
                        </form>
                        <div class="templates-heading">
                            <span>Custom Templates hinzufügen</span>
                            <div class="text-muted" style="font-size: 14px;font-weight: 500;">
                                <div>Wenn ein Eingabefeld leer ist, wird ein <span style="color: #5223ca;opacity: 0.64;">Standard-Template</span> verwendet.</div>
                                <div>Medien überschreiben: Die Chat-Medien, Benefits und die Beschreibung der Standort ersetzen die Medien, Benefits und die Beschreibung des Templates.</div>
                            </div>
                        </div>
                        <div formArrayName="jap_template_mapping" class="d-flex" style="flex-wrap: wrap;column-gap: 15px;">
                            <div *ngFor="let template of facility.get('jap_template_mapping')['controls']; let j=index"
                                [formGroupName]="j" class="form-group" style="padding: 0 !important;width: calc(50% - 8px)">
                                <label for="inputEmail4" class="d-flex justify-content-between">
                                    <div *ngIf="!template.get('demo_link').value" style="line-height: 21px;">{{template.get('job_category').value}}</div>
                                    <div *ngIf="template.get('demo_link').value" class="demo-link-btn" (click)="openLinkInNewTab(template.get('demo_link').value)">
                                        <span>{{template.get('job_category').value}}</span>
                                        <img class="jap-airplane-white" [src]="'assets/icons/logo-justapplai-airplane-white.svg'" alt="">
                                    </div>
                                    <div class="d-flex pl-1" style="align-items: center;font-size: 14px;">
                                        <div class="mr-1 pointer black-90-on-hover" (click)="toggleOnClick(i, 'jap_template_mapping', template.get('job_category').value)">Simple JAP?</div>
                                        <label class="switch" style="margin-bottom: 0px !important;">
                                            <input type="checkbox" (change)="toggleOnClick(i, 'jap_template_mapping', template.get('job_category').value)"
                                            [checked]="template.get('simple_jap').value">
                                            <span class="slider round"></span>
                                        </label>
                                    </div>
                                </label>
                                <div class="d-flex justify-content-between align-items-center" style="margin-top: -5px;font-size: 12px;">
                                    <div class="text-muted" style="font-size: 12px;margin-top: 1px;opacity: 0.8">
                                        <span *ngIf="template.get('template_name').value">Custom Template</span>
                                        <span *ngIf="!template.get('template_name').value">
                                            <span style="margin-top: 1px;font-weight: 500;color: #5223ca;opacity: 0.8;">
                                                {{template.get('simple_jap').value ?
                                                simpleJapTemplateNamesByJobCategory[template.get('job_category').value] :
                                                normalJapTemplateNamesByJobCategory[template.get('job_category').value]}}
                                            </span>
                                            <span> wird verwendet.</span>
                                        </span>
                                    </div>
                                    <div class="d-flex pointer" style="column-gap: 4px;">
                                        <span class="black-90-on-hover" style="font-size: 13px;opacity: 0.95;" (click)="overwriteTemplateMediaCheckboxOnClick(i, template.get('job_category').value)">Medien überschreiben?</span>
                                        <input type="checkbox" [checked]="template.get('overwrite_template_data').value == 1" (click)="overwriteTemplateMediaCheckboxOnClick(i, template.get('job_category').value)">
                                    </div>
                                </div>
                                <div class="d-flex text-muted" style="column-gap: 4px;margin-top: -2px;margin-bottom: 3px;font-size: 12px;opacity: 0.8">
                                    <div>Primärsprache:</div>
                                    <!-- LANGUAGE DROPDOWN -->
                                    <select formControlName="primary_language" id="inputState" class="form-control pointer" style="height: 20px;font-size: 11px;padding: 0px;"
                                            #mySelect (ngModelChange)="updateSelectWidth(mySelect)">
                                        <!-- <option value="" disabled selected hidden style="color: #495057 !important;">Alle</option> -->
                                        <option *ngFor="let language of languages" [ngValue]="language" style="color: #5223ca;">{{language}}</option>
                                    </select>
                                </div>
                                <input type="text" formControlName="template_name" class="form-control" id="inputEmail4" placeholder="Custom Template">
                            </div>
                        </div>
                    </div>
                </form>
            </div>
          </div>
        </div>
    </div>
    <div class="d-flex justify-content-center mt-3" style="column-gap: 10px;">
        <button class="btn btn-light" style="border: 2px solid rgba(0,0,0,0.4);color:rgba(0,0,0,0.7)" (click)="cancelFacilitiesConfiguration()">ABBRECHEN</button>
        <button class="btn btn-primary" [class.disabled-button]="facilitiesAreNotValidToSave() || facilities.length == 0" (click)="submitFacilitiesConfiguration()">SPEICHERN</button>
    </div>
</div>

<ng-template #deleteFacilityModal let-facilityIndex>
    <div style="padding: 30px;border-radius: 20px;background: white;">
        <div class="d-flex justify-content-center" style="column-gap: 15px;">
            <h5 style="margin-bottom: 20px;">Möchtest du die Standort wirklich löschen?</h5>
        </div>
        <div class="w-100 d-flex justify-content-center" style="column-gap: 10px;">
            <button class="btn btn-light" style="border: 2px solid rgba(0,0,0,0.4);background: none !important;" (click)="closeFacilityDeleteModal()">ABBRECHEN</button>
            <button *ngIf="facilityToBeDeleted?.get('id').value != null" class="btn btn-danger" (click)="deleteFacility()">LÖSCHEN</button>
            <button *ngIf="facilityToBeDeleted?.get('id').value == null" class="btn btn-danger" (click)="deleteFacility()">LÖSCHEN</button>
        </div>
    </div>
</ng-template>