import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AbstractService } from './abstract.service';

@Injectable({
  providedIn: 'root'
})
export class FacilityService extends AbstractService {

  constructor(private _http: HttpClient) {
    super(_http);
   }

  getAllOrders(): Observable<any> {
    return this.get('/orders/get-all-orders');
  }
  
  getAllOrdersForOverviewComponent(project: string = "all", fromDate: string = null, toDate: string = null): Observable<any> {
    let queryParams = 'project=' + project;
    if (fromDate) {
      queryParams += '&fromDate=' + fromDate;
      if (toDate) {
        queryParams += '&toDate=' + toDate;
      }
    }
    return this.get('/orders/get-all-orders-overview-data?' + queryParams);

  }
  
  getFacilitiesByOrderId(orderId: string): Observable<any> {
    return this.get(`/orders/get-facilities-by-order-id?order_id=${orderId}`);
  }
  
  extendFacilities(data: any): Observable<any> {
    return this.post('/orders/extend-facilities', data);
  }

  uploadMedia(file) {
    const formData = new FormData();
    formData.append('file', file);
    return this.post('/orders/upload', formData);
  }

  assignUserToOrder(data: any): Observable<any> {
    return this.post('/orders/assign-user-to-order', data)
  }

  verifyFacilities(facilityIDs: number[]) {
    return this.post('/job-collector/verify-facilities', {facilities: facilityIDs});
  }
  
  verifyJobs(jobIDs: string[]) {
    return this.post('/job-collector/verify-jobs', {ad_id: jobIDs});
  }
  
  getAllFacilities() {
    return this.get('/job-collector/get-all-facilities');
  }

  getJobsByFacilityIDs(facilityIDs: number[]) {
    return this.post('/job-collector/get-facilities-jobs', {facilities: facilityIDs});
  }

  reserveFacilitiesWithFacilityIDsAndState(facilityIDs: number[], state: string) {
    return this.post('/job-collector/reserve-facilities', {facilities: facilityIDs, state: state});
  }
  
  getFacilitiesReservationsByFacilityIDs(facilityIDs: number[]) {
    return this.post('/job-collector/get-facilities-reservations', {facilities: facilityIDs});
  }

  getAllFacilitiesReservations() {
    return this.get('/job-collector/get-all-reservations');
  }

  deleteOrder(order_id: string) {
    return this.delete('/orders/delete-order?order_id=' + order_id);
  }
  
  changeOrderEnddate(order_id: string, enddate: string) {
    return this.put('/orders/change-order-enddate', {order_id: order_id, end_date: enddate});
  }

  deleteFacility(id: string) {
    return this.delete('/orders/delete-facility?id=' + id);
  }

  getOrdersAndFaciliesBySearchTerm(searchTerm: string) {
    return this.get(`/orders/search-facilities?search_word=${searchTerm}`);
  }

  getCommentsByOrderIds(orderIds: any): Observable<any> {
    return this.post("/comments/get-comments-by-order-ids", {order_ids: orderIds})
  }
  
  getCommentsByFacilityIds(facilityIds: any): Observable<any> {
    return this.post("/comments/get-comments-by-facility-ids", {facility_ids: facilityIds})
  }

  saveComment(facilityId: number, orderId: number, comment: string): Observable<any> {
    return this.post("/comments/save", {facility_id: facilityId, order_id: orderId, comment: comment});
  }
  
  updateComment(commentId: number, comment: string): Observable<any> {
    return this.put(`/comments/${commentId}/update`, {comment: comment});
  }

  deleteComment(commentId: number): Observable<any> {
    return this.delete(`/comments/${commentId}/delete`);
  }

  // bpa auth Services:
  register(data: any): Observable<any> {
    return this._http.post(this.buildUrl("/auth/bpa-register"), data);
  }

  orderVerificationEmail(email: string, order_id: string): Observable<any> {
    return this._http.post(this.buildUrl("/auth/bpa-notifications"), {email: email, order_id: order_id, operation: "verify_order"});
  }

  sendBillInfoLater(email: string, order_id: string): Observable<any> {
    return this._http.post(this.buildUrl("/auth/bpa-notifications"), {email: email, order_id: order_id, operation: "bill_to_later"});
  }

  registerFacilitiesLater(email: string, order_id: string): Observable<any> {
    return this._http.post(this.buildUrl("/auth/bpa-notifications"), {email: email, order_id: order_id, operation: "configure_facilities_later"});
  }
  
  getMainFacilityData(email: string, order_id: string): Observable<any> {
    return this._http.get(this.buildUrl("/auth/bpa-main-facility-data"), {params: {email: email, order: order_id}});
  }

  verifyRegistration(email: string, order_id: string): Observable<any> {
    return this._http.post(this.buildUrl("/auth/verify-bpa-registration"), {email: email, order_id: order_id});
  }
  
  registerFacilities(data: any): Observable<any> {
    return this._http.post(this.buildUrl("/auth/bpa-register-facilities"), data);
  }
  
  bpaBusinessLog(order_id: string, session_id: string, operation: string, project: string): Observable<any> {
    return this._http.post(this.buildUrl("/auth/bpa-business-log"), {order_id: order_id, session_id: session_id, operation: operation, project: project});
  }

  getProjectConfig(project: string): Observable<any> {
    return this._http.get(this.buildUrl("/auth/get-project-config"), { params: { project: project }});
  }
  
  getAllProjectConfigs(): Observable<any> {
    return this._http.get(this.buildUrl("/auth/get-all-project-configs"));
  }

  getFacilityDemoLinksStatus(facilityID: string): Observable<any> {
    return this._http.get(this.buildUrl("/auth/facility-media-status"), { params: { facility_id: facilityID }});
  }

  rejectFacilityDemoLinks(facilityID: any): Observable<any> {
    return this._http.put(this.buildUrl("/auth/reject-facility-media"), {}, {params:{facility_id: facilityID}});
  }
  
  confirmFacilityDemoLinks(facilityID: any, confirmedBy?: any): Observable<any> {
    return this._http.put(this.buildUrl("/auth/confirm-facility-media"), {}, {params:{facility_id: facilityID, confirmed_by: confirmedBy}});
  }
}
