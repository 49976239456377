import { AfterViewInit, Component, ElementRef, Input, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { FormArray, FormBuilder, FormControl, Validators } from '@angular/forms';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { FacilityService } from '../../services/facility.service';
import {COMPANY_CATEGORIES, SIMPLE_JAP_TEMPLATES, NORMAL_JAP_TEMPLATES, BENEFITS} from '../../../@dubistpflege/constants/constants';
import { AuthService } from '../../services/auth.service';
import * as _ from 'lodash';
import { CommentsModalComponent } from '../comments-modal/comments-modal.component';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

@Component({
  selector: 'app-facilities-modal',
  templateUrl: './facilities-modal.component.html',
  styleUrls: ['./facilities-modal.component.scss']
})
export class FacilitiesModalComponent implements OnInit, AfterViewInit {

  @ViewChild('deleteFacilityModal') deleteFacilityModal: any;
  @ViewChildren('mySelect') mySelects: QueryList<ElementRef>;
  @ViewChildren('collapseElements') collapseElements: QueryList<ElementRef>;
  @Input() order_id: any; // UUID
  @Input() email: any;
  @Input() orderCompanyName: any;
  @Input() orderIntegerId: number;
  @Input() facilities: any;
  @Input() selectedFacilityCollapseId: any;
  @Input() orderComments: any;
  facilitiesCommentsDict = {};

  selectedFacilityTab: string;
  currentSlideIndex: number;
  permissionForMissingValues: boolean = false;

  // constants
  companyCategories = COMPANY_CATEGORIES;
  simpleJapTemplates = SIMPLE_JAP_TEMPLATES;
  simpleJapTemplateNamesByJobCategory: any;
  normalJapTemplates = NORMAL_JAP_TEMPLATES;
  normalJapTemplateNamesByJobCategory: any;
  benefits = BENEFITS;

  // RegEx
  urlRegEx = '(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?';
  youtubeRegEx = '(?:youtube(?:-nocookie)?\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|\S*?[?&]vi?=)|youtu\.be\/)([a-zA-Z0-9_-]{11})';
  vimeoRegEx = 'vimeo\.com\/[video\/]*([0-9]+)'
  youtubeVimeoRegEx = '(' + this.youtubeRegEx + ')|(' + this.vimeoRegEx + ')';

  facilitiesMustBeRegistered: boolean = false;
  facilityToBeDeleted: any;
  deleteFacilityModalReference: any;
  facilityIndexOfToBeDeletedFacility: number;
  permissionForManagingOrders: any;
  facilityWasDeleted: boolean;

  applicationDeliveryEmails = [];
  justapplaiEmails = [];
  applicationDeliveryEmailChanges = new Subject<string>();
  justapplaiEmailChanges = new Subject<string>();

  languages = ["DE", "DE-INFORMAL", "EN", "CS", "PL", "UK"];

  constructor(private _fb: FormBuilder,
              private _spinner: NgxSpinnerService,
              private _activeModal: NgbActiveModal,
              private _authService: AuthService,
              private _facilityService: FacilityService,
              private _modalService: NgbModal) { }

  ngOnInit(): void {
    if (this.orderComments.length == 0) {
      this._facilityService.getCommentsByOrderIds([this.order_id]).subscribe(res => {
        this.orderComments = res[0].comments;
      });
    }
    this._authService.getUserData().subscribe(res => {
      this.permissionForMissingValues = res.permissions.some(permission => {
        return permission.code == "FACILITY_MISSING_DATA"
      });
      this.permissionForManagingOrders = res.permissions.some(permission => {
        return permission.code == "MANAGE_ORDERS"
      });
    });
    this.setUpTemplateNames();
    this.initializeFacilitiesForms();
    this.setUpEmailChangePipes();
    this.selectedFacilityTab = "Stammdaten";
  }

  ngAfterViewInit() {
    if (this.selectedFacilityCollapseId != undefined) {
      this.collapse(this.selectedFacilityCollapseId);
    }
  }

  setUpEmailChangePipes() {
    // App delivery email
    this.applicationDeliveryEmailChanges.pipe(
      debounceTime(400) // debounce time in milliseconds
    ).subscribe(() => {
      this.facilities.forEach((facility, index) => {
        facility.get("application_delivery_email").setValue(this.applicationDeliveryEmails[index].filter(email => email.length > 0).join(";"));
      });
    });
    // justapplai email
    this.justapplaiEmailChanges.pipe(
      debounceTime(400) // debounce time in milliseconds
    ).subscribe(() => {
      this.facilities.forEach((facility, index) => {
        facility.get("justapplai_email").setValue(this.justapplaiEmails[index].filter(email => email.length > 0).join(";"));
      });
    });
  }

  selectJapTab() {
    this.selectedFacilityTab = 'justappl.ai';
    setTimeout(() => {
      if (this.mySelects) {
        this.mySelects.forEach(select => this.updateSelectWidth(select.nativeElement));
      }
    });
  }

  updateSelectWidth(selectElement: HTMLSelectElement): void {
    const selectedOption = selectElement.selectedOptions[0];
    const fontSize = 11; // adjust as needed
    const textWidth = selectedOption.text.length * fontSize * 0.6 + 25; // adjust 0.6 as needed
    selectElement.style.width = `${textWidth}px`;
  }

  collapse(index: number) {
    const collapse = this.collapseElements.toArray()[index-1].nativeElement;
    collapse.classList.toggle('show');
  }

  openOrderComments() {
    const modal = this._modalService.open(CommentsModalComponent, {size: 'm', windowClass: 'modal-position', scrollable: true});
    modal.componentInstance.comments = this.orderComments;
    modal.componentInstance.orderId = this.orderIntegerId;
    modal.componentInstance.facilityId = null;
    modal.componentInstance.subjectName = this.orderCompanyName;
  }

  openFacilityComments(facility: any) {
    const modal = this._modalService.open(CommentsModalComponent, {size: 'm', windowClass: 'modal-position', scrollable: true});
    modal.componentInstance.comments = this.facilitiesCommentsDict[facility.get("id").value];
    modal.componentInstance.orderId = null;
    modal.componentInstance.facilityId = facility.get("id").value;
    modal.componentInstance.subjectName = facility.get("company_name").value;
  }

  setUpTemplateNames() {
    this.simpleJapTemplateNamesByJobCategory = {}
    this.normalJapTemplateNamesByJobCategory = {}
    this.simpleJapTemplates.forEach(template => {
      this.simpleJapTemplateNamesByJobCategory[template.job_category] = template["template_name"];
    });
    this.normalJapTemplates.forEach(template => {
      this.normalJapTemplateNamesByJobCategory[template.job_category] = template["template_name"];
    });
  }

  initializeFacilitiesForms() {
    // check if facilities were configured
    this.facilitiesMustBeRegistered = !this.facilities[0];

    this.facilities = this.facilities.map((facility, index) => {
      if (facility?.id != null) {
        this.facilitiesCommentsDict[facility.id] = [];
      }
      let facilityFormBuilderGroup = this._fb.group({
        main_project_id: [facility?.main_project_id],
        main_project: [facility?.main_project],
        project_id: [facility?.project_id],
        project: [facility?.project],
        id: [facility?.id],
        facility_id: [facility?.facility_id],
        company_name: [!facility?.company_name ? '' : facility.company_name, Validators.required],
        zip: [!facility?.zip ? '' : facility.zip, [Validators.pattern(/^\d+$/), Validators.minLength(5), Validators.maxLength(5), Validators.required]],
        city: [!facility?.city ? '' : facility.city, Validators.required],
        street: [!facility?.street ? '' : facility.street, Validators.required],
        carrer_site_url: [!facility?.carrer_site_url ? '' : facility.carrer_site_url],
        carrer_site_missing: [facility?.carrer_site_missing ? facility.carrer_site_missing : 0],
        facility_domain_url: [!facility?.facility_domain_url ? '' : facility.facility_domain_url],
        facility_domain_missing: [facility?.facility_domain_missing ? facility.facility_domain_missing : 0],
        company_category: [!facility?.company_category ? '' : facility.company_category, [Validators.minLength(1), Validators.required]],
        justapplai_email: [!facility?.justapplai_email ? this.email : facility.justapplai_email, [Validators.required]],
        application_delivery_email: [!facility?.application_delivery_email ? '' : facility.application_delivery_email, [Validators.required]],
        recruiting_space_code: [facility?.recruiting_space_code == null ? '' : facility.recruiting_space_code],
        gdpr_link: [!facility?.gdpr_link ? '' : facility.gdpr_link],
        gdpr_data_missing: [facility?.gdpr_data_missing ? facility.gdpr_data_missing : 0],
        gdpr_company_name: [!facility?.gdpr_company_name ? '' : facility.gdpr_company_name],
        facility_description: [!facility?.facility_description ? '' : facility.facility_description],
        benefits: [facility?.benefits ? facility.benefits?.split(", ") : []],
        facility_logo: [!facility?.facility_logo? '' : facility.facility_logo],
        q1_image_url: [!facility?.q1_image_url ? '' : facility.q1_image_url],
        q1_video_url: [!facility?.q1_video_url ? '' : facility.q1_video_url],
        q2_image_url: [!facility?.q2_image_url ? '' : facility.q2_image_url],
        q2_video_url: [!facility?.q2_video_url ? '' : facility.q2_video_url],
        q3_image_url: [!facility?.q3_image_url ? '' : facility.q3_image_url],
        q3_video_url: [!facility?.q3_video_url ? '' : facility.q3_video_url],
        q4_image_url: [!facility?.q4_image_url ? '' : facility.q4_image_url],
        q4_video_url: [!facility?.q4_video_url ? '' : facility.q4_video_url],
        q5_image_url: [!facility?.q5_image_url ? '' : facility.q5_image_url],
        q5_video_url: [!facility?.q5_video_url ? '' : facility.q5_video_url],
        q6_image_url: [!facility?.q6_image_url ? '' : facility.q6_image_url],
        q6_video_url: [!facility?.q6_video_url ? '' : facility.q6_video_url],
        change_started_email: [!facility?.change_started_email ? '' : facility.change_started_email],
        change_started_name: [!facility?.change_started_name ? '' : facility.change_started_name],
        change_started_at: [!facility?.change_started_at ? '' : facility.change_started_at],
        change_updated_email: [!facility?.change_updated_email ? '' : facility.change_updated_email],
        change_updated_name: [!facility?.change_updated_name ? '' : facility.change_updated_name],
        change_updated_at: [!facility?.change_updated_at ? '' : facility.change_updated_at],
        facility_completed: [!facility?.facility_completed ? 0 : facility.facility_completed],
        simple_jap: [facility?.simple_jap != null ? facility.simple_jap : 1],
        jap_template_mapping: this._fb.array([]),
        demo_links_confirmed_at: [!facility?.demo_links_confirmed_at ? '' : facility.demo_links_confirmed_at],
        demo_links_rejected_at: [!facility?.demo_links_rejected_at ? '' : facility.demo_links_rejected_at],
        demo_links_sent_at: [!facility?.demo_links_sent_at ? '' : facility.demo_links_sent_at],
        demo_links_confirmed_by: [!facility?.demo_links_confirmed_by ? '' : facility.demo_links_confirmed_by],
        demo_links_reminder_sent_at: [!facility?.demo_links_reminder_sent_at ? '' : facility.demo_links_reminder_sent_at],
        demo_links: [!facility?.demo_links ? [] : facility.demo_links],
        facility_live_at: [!facility?.facility_live_at ? '' : facility.facility_live_at]
      });
      this.applicationDeliveryEmails.push([]);
      this.justapplaiEmails.push([]);
      if (facilityFormBuilderGroup.get("application_delivery_email").value) {
        facilityFormBuilderGroup.get("application_delivery_email").value.split(";").forEach(email => this.applicationDeliveryEmails[index].push(email));
      } else {
        this.applicationDeliveryEmails[index].push("");
      }
      if (facilityFormBuilderGroup.get("justapplai_email").value) {
        facilityFormBuilderGroup.get("justapplai_email").value.split(";").forEach(email => this.justapplaiEmails[index].push(email));
      } else {
        this.justapplaiEmails[index].push("");
      }
      let control = <FormArray>facilityFormBuilderGroup.get('jap_template_mapping');
      // TODO: If order has jap templates, then add them
      // if templates exist on facility, check if they are default, if yes, remove them, else add them
      if (facility?.jap_template_mapping?.length > 0) {
        let hasHeilerziehungspflegeTemplate = false;
        facility?.jap_template_mapping.forEach(template => {
          if (template.job_category == "Heilerziehungspflege") {
            hasHeilerziehungspflegeTemplate = true;
          }
          let isDefaultTemplate = !this.templateNameIsCustom(template.template_name);
          if (isDefaultTemplate) {
            template.template_name = "";
            control.push(this.getFormGroupOfTemplate(template, facilityFormBuilderGroup.value));
          } else {
            control.push(this.getFormGroupOfTemplate(template, facilityFormBuilderGroup.value));
          }
        });
        if (!hasHeilerziehungspflegeTemplate) {
          let template = this.simpleJapTemplates.find(template => template.job_category == "Heilerziehungspflege");
          template.template_name = "";
          control.push(this.getFormGroupOfTemplate(template, facilityFormBuilderGroup.value));
        }
      } else {
        if (facilityFormBuilderGroup.get("simple_jap").value) {
          this.simpleJapTemplates.forEach(simpleJapTemplate => {
            let templ = _.cloneDeep(simpleJapTemplate);
            templ.template_name = "";
            control.push(this.getFormGroupOfTemplate(templ, facilityFormBuilderGroup.value));
          });
        } else {
          this.normalJapTemplates.forEach(normalJapTemplate => {
            let templ = _.cloneDeep(normalJapTemplate);
            templ.template_name = "";
            control.push(this.getFormGroupOfTemplate(templ, facilityFormBuilderGroup.value));
          });
        }
      }
      control.value.sort((a,b) => {
        return a["job_category"].localeCompare(b["job_category"]);
      });
      control.setValue(control.value);
      return facilityFormBuilderGroup;
    });
    if (Object.keys(this.facilitiesCommentsDict).length > 0) {
      this._facilityService.getCommentsByFacilityIds(Object.keys(this.facilitiesCommentsDict)).subscribe(res => {
        if (res.length > 0) {
          res.forEach(element => {
            this.facilitiesCommentsDict[element["id"]] = element["comments"];
          });
        }
      });
    }
    this.facilities.forEach(facility => {
      facility.valueChanges.subscribe(() => {
        if (facility.get("facility_completed").value && !facility.valid) {
          facility.get("facility_completed").setValue(0);
        }
      });
    });
  }

  onApplicationDeliveryEmailChange() {
    this.applicationDeliveryEmailChanges.next();
  }
  
  onJustapplaiEmailChange() {
    this.justapplaiEmailChanges.next();
  }

  deleteApplicationDeliveryEmail(facilityIndex: number, emailInputIndex) {
    this.applicationDeliveryEmails[facilityIndex].splice(emailInputIndex, 1);
    this.onApplicationDeliveryEmailChange();
  }
  
  deleteJustapplaiEmail(facilityIndex: number, emailInputIndex) {
    this.justapplaiEmails[facilityIndex].splice(emailInputIndex, 1);
    this.onJustapplaiEmailChange();
  }

  trackByFn(index, item) {
    return index; // or item.id if you have an unique identifier for each item
  }

  imageIsValid(input: FormControl) {
    if (input?.value) {
      if (input.value.match(/(http(s?):)([/|.|\w|\s|-])*\.(?:jpe?g|gif|png|svg|bmp)/)) {
        return new Promise((resolve) => { resolve(null)});
      } else {
        const img = new Image();
        img.src = input.value;
        return new Promise((resolve) => {
          if (input.value.includes("base64")) {
            resolve({"invalidImageUrl": true});
          } else {
            img.onload = () => {
              resolve(null);
            }
            img.onerror = () => {
              resolve({"invalidImageUrl": true});
            }
          }
        });
      }
    } else {
      return new Promise((resolve) => {
        resolve(null);
      });
    }
  }

  urlIsYoutubeVideoOrVimeoVideo(input: FormControl) {
    if (input?.value) {
      const youtube = /(?:youtube(?:-nocookie)?\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|\S*?[?&]vi?=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/.exec(input.value.trim());
      const vimeo = /vimeo\.com\/[video\/]*([0-9]+)/.exec(input.value.trim());
      if ((youtube && youtube.length > 0) || (vimeo && vimeo.length > 0)) {
        return null;
      } else {
        return { "invalidVideoUrl": true };
      }
    } else {
      return null;
    }
  }

  facilityMainDataAreInvalid(facility: any) {
    return !facility?.get("company_name").valid ||
            !facility?.get("zip").valid ||
            !facility?.get("city").valid ||
            !facility?.get("street").valid ||
            !facility?.get("company_category").valid ||
            !facility?.get("application_delivery_email").valid;
  }

  facilitiesAreNotValidToSave() {
    return this.facilities.some(facility => {
      return !facility?.get("company_name").valid ||
            !facility?.get("zip").valid ||
            !facility?.get("city").valid ||
            !facility?.get("street").valid ||
            !facility?.get("company_category").valid;
    });
  }

  imageUrlsAreInvalid(facility: any) {
    return facility?.get("q1_image_url").errors ||
            facility?.get("q2_image_url").errors ||
            facility?.get("q3_image_url").errors ||
            facility?.get("q4_image_url").errors ||
            facility?.get("q5_image_url").errors ||
            facility?.get("q6_image_url").errors;
  }
  
  logoImageUrlIsInvalid(facility: any) {
    return facility?.get("facility_logo").errors;
  }

  videoUrlsAreInvalid(facility: any) {
    return facility?.get("q1_video_url").errors ||
            facility?.get("q2_video_url").errors ||
            facility?.get("q3_video_url").errors ||
            facility?.get("q4_video_url").errors ||
            facility?.get("q5_video_url").errors ||
            facility?.get("q6_video_url").errors;
  }

  getFormGroupOfTemplate(template: any, facility?: any) {
    return this._fb.group({
      template_name: [template.template_name],
      job_category: [template.job_category],
      simple_jap: [template.hasOwnProperty("simple_jap") ? template.simple_jap : facility.simple_jap],
      is_custom: [template.template_name ? 1 : 0],
      overwrite_template_data: [template.hasOwnProperty("overwrite_template_data") ? template.overwrite_template_data : 1],
      primary_language: [template.hasOwnProperty("primary_language") ? template.primary_language : "DE"],
      demo_link: [facility?.demo_links?.length > 0 ? facility.demo_links.find(link => link.job_category == template.job_category).url : '']
    });
  }

  templateNameIsCustom(templateName: string) {
    let templates = [...this.simpleJapTemplates, ...this.normalJapTemplates];
    return !templates.some(template => template.template_name == templateName);
  }

  openLinkInNewTab(url: string) {
    window.open(url, "_blank");
  }

  handleFileInput(files: FileList, facilityIndex, questionNumber): void {
    this._spinner.show();
    let keyName;
    if (questionNumber == 7) {
      keyName = "facility_logo";
    } else {
      keyName = "q" + questionNumber + "_image_url";
    }
    this._facilityService.uploadMedia(files.item(0)).subscribe(data => {
      this.facilities[facilityIndex].get(keyName).setValue(data["url"]);
      this._spinner.hide();
    });
  }

  deleteImage(facilityIndex: number, key: string) {
    this.facilities[facilityIndex].get(key).setValue('');
  }

  enableValidationForFacility(facility: any, facilityIndex: number) {
    this._facilityService.confirmFacilityDemoLinks(facility.get("id").value, "support").subscribe(res => {
      this._facilityService.getFacilityDemoLinksStatus(facility.get("id").value).subscribe(res => {
        this.facilities[facilityIndex].patchValue({
          demo_links_confirmed_at: res.demo_links_confirmed_at,
          demo_links_confirmed_by: res.demo_links_confirmed_by,
          demo_links_rejected_at: res.demo_links_rejected_at,
          demo_links_sent_at: res.demo_links_sent_at
        });
      });
    });
  }

  openFacilityDeleteModal(facility: any, facilityIndex: number) {
    this.facilityToBeDeleted = facility;
    this.facilityIndexOfToBeDeletedFacility = facilityIndex;
    this.deleteFacilityModalReference = this._modalService.open(this.deleteFacilityModal, {
      size: 'md'
    });
  }

  closeFacilityDeleteModal() {
    this.facilityToBeDeleted = null;
    this.facilityIndexOfToBeDeletedFacility = null;
    this.deleteFacilityModalReference.close();
  }

  deleteFacility() {
    this._spinner.show();
    if (this.facilityToBeDeleted.get("id").value != null) {
      this._facilityService.deleteFacility(this.facilityToBeDeleted.get("id").value).subscribe(() => {
        this.facilityWasDeleted = true;
        this.removeDeletedFacilityFromFacilitiesArray();
        this.closeFacilityDeleteModal();
        //this._activeModal.close({facilitiesSubmitted: true});
        this._spinner.hide();
      });
    } else {
      this.removeDeletedFacilityFromFacilitiesArray();
      this.closeFacilityDeleteModal();
      this._spinner.hide();
    }
  }

  removeDeletedFacilityFromFacilitiesArray() {
    this.facilities.splice(this.facilityIndexOfToBeDeletedFacility, 1); 
  }
  
  addFacilityToFacilitiesArray() {
    let facilityFormBuilderGroup = this._fb.group({
      main_project_id: [null],
      main_project: [null],
      project_id: [null],
      project: [null],
      id: [null],
      facility_id: [null],
      company_name: ['', Validators.required],
      zip: ['', [Validators.pattern(/^\d+$/), Validators.minLength(5), Validators.maxLength(5), Validators.required]],
      city: ['', Validators.required],
      street: ['', Validators.required],
      carrer_site_url: [''],
      carrer_site_missing: [0],
      facility_domain_url: [''],
      facility_domain_missing: [0],
      company_category: ['', [Validators.minLength(1), Validators.required]],
      justapplai_email: [this.email, [Validators.required]],
      application_delivery_email: ['', [Validators.required]],
      recruiting_space_code: [''],
      gdpr_link: [''],
      gdpr_data_missing: [0],
      gdpr_company_name: [''],
      facility_description: [''],
      benefits: [[]],
      facility_logo: [''],
      q1_image_url: [''],
      q1_video_url: [''],
      q2_image_url: [''],
      q2_video_url: [''],
      q3_image_url: [''],
      q3_video_url: [''],
      q4_image_url: [''],
      q4_video_url: [''],
      q5_image_url: [''],
      q5_video_url: [''],
      q6_image_url: [''],
      q6_video_url: [''],
      change_started_email: [''],
      change_started_name: [''],
      change_started_at: [''],
      change_updated_email: [''],
      change_updated_name: [''],
      change_updated_at: [''],
      facility_completed: [0],
      simple_jap: [1],
      jap_template_mapping : this._fb.array([]),
      demo_links_confirmed_at: [''],
      demo_links_rejected_at: [''],
      demo_links_sent_at: [''],
      demo_links_confirmed_by: [''],
      demo_links_reminder_sent_at: [''],
      facility_live_at: ['']
    });
    let control = <FormArray>facilityFormBuilderGroup.get('jap_template_mapping');
    if (facilityFormBuilderGroup.get("simple_jap").value) {
      this.simpleJapTemplates.forEach(simpleJapTemplate => {
        let templ = _.cloneDeep(simpleJapTemplate);
        templ.template_name = "";
        control.push(this.getFormGroupOfTemplate(templ, facilityFormBuilderGroup.value));
      });
    } else {
      this.normalJapTemplates.forEach(normalJapTemplate => {
        let templ = _.cloneDeep(normalJapTemplate);
        templ.template_name = "";
        control.push(this.getFormGroupOfTemplate(templ, facilityFormBuilderGroup.value));
      });
    }
    control.value.sort((a,b) => {
      return a["job_category"].localeCompare(b["job_category"]);
    });
    control.setValue(control.value);
    
    facilityFormBuilderGroup.valueChanges.subscribe(() => {
      if (facilityFormBuilderGroup.get("facility_completed").value && !facilityFormBuilderGroup.valid) {
        facilityFormBuilderGroup.get("facility_completed").setValue(0);
      }
    });
    this.facilities.push(facilityFormBuilderGroup);
    this.applicationDeliveryEmails.push([]);
    this.justapplaiEmails.push([]);
    this.applicationDeliveryEmails[this.facilities.length-1].push("");
    this.justapplaiEmails[this.facilities.length-1].push(this.email);
  }

  applyValueForAllFacilities(keyName: string, facilityIndex: string, missingValueKey?: string) {
    let value = this.facilities[facilityIndex].get(keyName).value;
    let missingValue = missingValueKey ? this.facilities[facilityIndex].get(missingValueKey).value : null;
    this.facilities.forEach(facility => {
      facility.get(keyName).setValue(value);
      if (missingValue != null) {
        facility.get(missingValueKey).setValue(missingValue);
      }
    });
  }

  benefitChecked(benefit, index): boolean {
    return this.facilities[index].get("benefits").value?.indexOf(benefit) !== -1;
  }

  benefitChanged(benefit, index): void {
    let benefits = this.facilities[index].get("benefits").value;
    const i = benefits?.indexOf(benefit);
    if (i === -1) {
      benefits.push(benefit);
      this.facilities[index].get("benefits").setValue(benefits)
      if (benefits.length > 9) {
        setTimeout(() => benefits.pop(), 250);
      }
    } else {
      benefits = benefits?.filter((i) => i !== benefit);
      this.facilities[index].get("benefits").setValue(benefits);
    }
  }

  slideChange(event) {
    this.currentSlideIndex = event.current;
  }

  toggleOnClick(facilityIndex: string, facilityKey: string, templateJobCategory?: string) {
    if (facilityKey == "jap_template_mapping") {
      const control = <FormArray>this.facilities[facilityIndex].get('jap_template_mapping');
      control.controls.forEach(template => {
        if (template.get("job_category").value == templateJobCategory) {
          if (template.get("simple_jap").value) {
            template.get("simple_jap").setValue(0);
          } else {
            template.get("simple_jap").setValue(1);
          }
        }
      });
    } else {
      if (this.facilities[facilityIndex].get(facilityKey).value) {
        this.facilities[facilityIndex].get(facilityKey).setValue(0);
      } else {
        this.facilities[facilityIndex].get(facilityKey).setValue(1);
      }
    }
  }

  overwriteTemplateMediaCheckboxOnClick(facilityIndex: string, templateJobCategory: string) {
    const control = <FormArray>this.facilities[facilityIndex].get('jap_template_mapping');
    control.controls.forEach(template => {
      if (template.get("job_category").value == templateJobCategory) {
        if (template.get("overwrite_template_data").value) {
          template.get("overwrite_template_data").setValue(0);
        } else {
          template.get("overwrite_template_data").setValue(1);
        }
      }
    });
  }

  cancelFacilitiesConfiguration() {
    this._activeModal.close({facilitiesSubmitted: true});
  }

  submitFacilitiesConfiguration() {
    this._spinner.show();
    let facilityDataIsMissing: boolean = false;
    let data = {
      order_id: this.order_id,
      email: this.email,
      facilities: []
    }
    this.facilities.forEach((facility, index) => {
      facility.get("application_delivery_email").setValue(this.applicationDeliveryEmails[index].filter(email => email.length > 0).join(";"));
      facility.get("justapplai_email").setValue(this.justapplaiEmails[index].filter(email => email.length > 0).join(";"));
      if (!facilityDataIsMissing && (facility.get("carrer_site_missing").value ||
          facility.get("facility_domain_missing").value ||
          facility.get("gdpr_data_missing").value)) {
        facilityDataIsMissing = true;
      }
      let benefits = "";
      if (facility.value.benefits?.length > 1) {
        benefits = facility.value.benefits.join(", ");
      } else if (facility.value.benefits?.length == 1) {
        benefits = facility.value.benefits[0];
      }
      facility.get("benefits").setValue(benefits, {emitEvent: false});
      facility.get("q3_image_url").setValue(facility.get("q2_image_url").value, {emitEvent: false});
      facility.get("q3_video_url").setValue(facility.get("q2_video_url").value, {emitEvent: false});

      // set default template names when there is no custom value set
      const control = <FormArray>facility.get('jap_template_mapping');
      control.controls.forEach(template => {
        if (!template.get("template_name").value) {
          template.get("is_custom").setValue(0);
          if (template.get("simple_jap").value) {
            template.get("template_name").setValue(this.simpleJapTemplates.find(simpleJapTemplate => {
              return template.get("job_category").value == simpleJapTemplate.job_category;
            }).template_name);
          } else {
            template.get("template_name").setValue(this.normalJapTemplates.find(normalJapTemplate => {
              return template.get("job_category").value == normalJapTemplate.job_category;
            }).template_name);
          }
        } else if (this.templateNameIsCustom(template.get("template_name").value)) {
          template.get("is_custom").setValue(1);
        }
      });
      let facilityObj = facility.value;
      facilityObj['jap_template_mapping'].forEach(template => delete template.demo_link);
      data.facilities.push(facilityObj);
    });
    if (facilityDataIsMissing) {
      data["facility_missing_data"] = 1;
    } else {
      data["facility_missing_data"] = 0;
    }

    this._facilityService.extendFacilities(data).subscribe(res => {
      this._spinner.hide();
      this._activeModal.close({facilitiesSubmitted: true});
    }, err => {
      this._spinner.hide();
      this._activeModal.close({facilitiesSubmitted: false});
    });

  }

}
