import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {HasPermissionDirective} from './has-permission.directive';
import {SortDirective} from './sort-directive';



@NgModule({
  declarations: [
    HasPermissionDirective,
    SortDirective
  ],
  imports: [
    CommonModule
  ],
  exports:[
    HasPermissionDirective,
    SortDirective
  ]
})
export class DirectivesModule { }
