import {Observable} from 'rxjs';
import {Injectable} from '@angular/core';
import {AbstractStore} from './abstract.store';

@Injectable({
  providedIn: 'root'
})
export class Store extends AbstractStore{

  // optional
  user$: Observable<any>;
  skills$: Observable<any>;
  subSkills$: Observable<any>;
  countries$: Observable<any>;

  constructor() {
    super({
      'user': null,
      'skills': null,
      'subSkills': null,
      'countries': null,
    });
  }
}




