import {TranslateService} from '@ngx-translate/core';
import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable, of} from 'rxjs';
import {v4 as uuid} from 'uuid';
import {AbstractService} from './abstract.service';
import {tap} from 'rxjs/operators';
import {Store} from './store';
import {UserService} from './user.service';


@Injectable({providedIn: 'root'})
export class AuthService extends AbstractService {
  constructor(protected http: HttpClient,
              public translate: TranslateService,
              private userService: UserService,
              private store: Store) {
    super(http);
  }

  login(user): Observable<any> {
    return this.http.post(this.buildUrl('/auth/login'), {
      email: user.email,
      password: user.password
    }).pipe(
      tap((data: any) => localStorage.setItem('token', data.original.access_token))
    );
  }

  register(payload): Observable<any> {
    return this.http.post(this.buildUrl('/auth/register'), payload);
  }


  forgetPass(user): Observable<any> {
    return this.http.post(this.buildUrl('/auth/forget'), {
      email: user.email
    });
  }

  resetPass(payload): Observable<any> {
    return this.http.post(this.buildUrl('/auth/reset-password'), payload);
  }

  logout() {
    localStorage.removeItem('token');
    this.store.setStore('user', null);
  }

  getSession() {
    let session = sessionStorage.getItem('session_id');
    if (!session) {
      session = uuid();
      sessionStorage.setItem('session_id', session);
    }
    return session;
  }

  getUserData() {
    return this.http.get(this.buildUrl('/auth/init'))
      .pipe(tap((data: any) => this.store.setStore("user", data)));
  }

  checkResetPasswordToken(email, token) {
    return this.http.get(this.buildUrl('/auth/verify-reset-password-token'), {
      params: {
        token: token,
        email: encodeURIComponent(email)
      }
    });
  }

  getToken() {
    return localStorage.getItem('token');
  }

  hasToken() {
    return !!this.getToken();
  }

  getUser() {
    return this.store.getStore('user');
  }

  getUserId() {
    return this.store.getStore('user') ? this.store.getStore('user').id : null;
  }

  getUserName() {
    if (!this.store.getStore('user').first_name) {
      return 'Account';
    }

    if (!this.store.getStore('user').last_name) {
      return this.store.getStore('user').first_name;
    }

    return this.store.getStore('user').first_name + ' ' + this.store.getStore('user').last_name;
  }

  isLoggedIn() {
    return this.hasToken() && this.store.getStore('user');
  }

  changeLanguage(languageCode, dbChange = true){
    this.translate.use(languageCode);
    localStorage.setItem('language', languageCode);
    if (dbChange && this.isLoggedIn())
      this.userService.updateUser(this.getUserId(), {'language': languageCode.toUpperCase()}, true).toPromise().then();
  }

  hasPermission(permissionCode: string, readWrite = false) {
    const permission = this.getUser().permissions.find(item => item.code == permissionCode);
    return permission && (!readWrite || permission.access_right == 'READ_WRITE');
  }
}
