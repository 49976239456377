import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subject } from 'rxjs';
import { AuthService } from '../../services/auth.service';
import { FacilityService } from '../../services/facility.service';

@Component({
  selector: 'app-comments-modal',
  templateUrl: './comments-modal.component.html',
  styleUrls: ['./comments-modal.component.scss']
})
export class CommentsModalComponent implements OnInit {

  @Input() comments: any;
  @Input() subjectName: any;
  @Input() orderId: number;
  @Input() facilityId: number;
  
  // facilities will ony be set when facilities are grouped in the job collector
  @Input() facilities: any;

  userId: number;
  userFirstName: string;
  userLastName: string;

  input: string;
  editMode: boolean;
  commentIdToBeEdited: any;
  showEditWarning: boolean;
  userDataLoaded: boolean = false;

  groupedSubjects: any;
  userChatPermission: any;

  constructor(private _authService: AuthService,
              private _facilityService: FacilityService,
              private _spinner: NgxSpinnerService) { }

  ngOnInit(): void {
    this._spinner.show();
    this._authService.getUserData().subscribe(res => {
      this.userId = res.id;
      this.userFirstName = res.first_name;
      this.userLastName = res.last_name;
      this.userDataLoaded = true;
      this.userChatPermission = res.permissions.find(permission => permission.code == "CHAT");
      this._spinner.hide();
    });
    if (this.facilities) {
      this.prepareGroupedReadOnlyComments();
    }
  }

  prepareGroupedReadOnlyComments() {
    if (this.subjectName == "Standorte") {
      this.groupedSubjects = this.facilities.reduce((accumulator, facility) => {
        if (facility?.facility_comments) {
          if (facility?.facility_comments[0]) {
            let commentsObj = facility.facility_comments[0];
            commentsObj["subjectName"] = facility.facility_name;
            accumulator.push(commentsObj);
          } else {
            let commentsObj = facility.facility_comments;
            commentsObj["subjectName"] = facility.company_name;
            accumulator.push(commentsObj);
          }
        }
        return accumulator;
      }, []).filter(group => group.hasOwnProperty("id"));
    } else {
      this.groupedSubjects = this.facilities.reduce((accumulator, facility) => {
        let orderExistsAlready = accumulator.find(orderObj => orderObj.id === facility.order_comments[0].id);
        if (!orderExistsAlready && facility.order_comments[0].comments.length > 0) {
          let commentsObj = facility.order_comments[0];
          commentsObj["subjectName"] = facility.company_name;
          commentsObj["orderId"] = facility.order_id;
          accumulator.push(commentsObj);
        }
        return accumulator;
      }, []);
    }
  }

  onInputChange(event: any) {
    if (this.input) {
      this.showEditWarning = false;
    }
  }

  onEnterClickEvent(event: any) {
    this.input = this.input ? this.input.trim() : "";
    if (this.editMode) {
      if (this.input) {
        this._spinner.show();
        this._facilityService.updateComment(this.commentIdToBeEdited ,this.input).subscribe(res => {
          let positionInArray;
          this.comments.forEach((comment, index) => {
            if (comment.id == res.id) {
              positionInArray = index;
            }
          });
          this.comments.splice(positionInArray, 1);
          this.comments.splice(positionInArray, 0, res);
          this.commentIdToBeEdited = null;
          this.editMode = false;
          this.input = "";
          this._spinner.hide();
        });
      } else {
        this.showEditWarning = true;
        this._spinner.hide();
      }
    } else {
      if (this.input) {
        this._spinner.show();
        this._facilityService.saveComment(this.facilityId, this.orderId, this.input).subscribe(res => {
          this.comments.push(res)
          this.input = "";
          this._spinner.hide();
        });
      }
    }
  }

  editComment(comment: any) {
    this.editMode = true;
    this.commentIdToBeEdited = comment.id;
    this.input = comment.comment;
  }

  cancelEditing() {
    this.input = "";
    this.editMode = false;
    this.commentIdToBeEdited = null;
    this.showEditWarning = false;
  }

  deleteComment(comment: any) {
    this._spinner.show();
      this._facilityService.deleteComment(comment.id).subscribe(res => {
        let index = this.comments.indexOf(comment);
        if (index !== -1) {
          this.comments.splice(index, 1);
        }
        this._spinner.hide();
      });
  }
  
  getRandomColor(userId: any) {
    // Set the seed value based on the user ID
    let seed = userId * 17 + 23;
  
    // Generate three random numbers between 0 and 255 using the seed
    let r = Math.floor(Math.abs(Math.sin(seed++) * 256));
    let g = Math.floor(Math.abs(Math.sin(seed++) * 256));
    let b = Math.floor(Math.abs(Math.sin(seed++) * 256));
  
    // Calculate the brightness value of the color
    let brightness = (r * 299 + g * 587 + b * 114) / 1000;
  
    // Adjust the brightness if necessary to ensure good readability on white background
    if (brightness > 160) {
      r = Math.floor(r * 0.7);
      g = Math.floor(g * 0.7);
      b = Math.floor(b * 0.7);
    }
  
    // Return the color as a hex string
    return "#" + r.toString(16).padStart(2, "0") + g.toString(16).padStart(2, "0") + b.toString(16).padStart(2, "0");
  }
  

}
