import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {AbstractService} from './abstract.service';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UserService extends AbstractService {

  constructor(protected http: HttpClient) {
    super(http);
  }


  getAllUsers() {
    return this.get('/users');
  }

  updateUser(userId: number, payload: any, silent = false): Observable<any> {
    let options = {};
    if (silent) {
      options = this.silentOption();
    }
    return this.put(`/users/${userId}`, payload, options);
  }

  resetPassword(userId: number, payload: any): Observable<any> {
    return this.put(`/users/${userId}/change-password`, payload);
  }

  activeUser(userId: number, payload: any): Observable<any> {
    return this.post(`/users/${userId}/activate`, payload);
  }

  deactivateUser(userId): Observable<any> {
    return this.post(`/users/${userId}/deactivate`, {});
  }

  deleteUser(userId: number): Observable<any> {
    return this.delete(`/users/${userId}`);
  }
}
