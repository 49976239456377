import {Component, Input, OnInit} from '@angular/core';
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {Router} from '@angular/router';
import {UserResolver} from '../../services/user.resolver';
import {TranslateService} from '@ngx-translate/core';
import {AuthService} from '../../services/auth.service';

@Component({
  selector: 'app-admin-layout',
  templateUrl: './admin-layout.component.html',
  styleUrls: ['./admin-layout.component.scss']

})
export class AdminLayoutComponent implements OnInit {
  public isCollapsed = true;
  public currentYear = new Date().getFullYear()
  closeResult = '';
  credit_clicked:boolean;
  billing_clicked:boolean = true;
  selectedSidebarItem: string;

  languages = [
    {
      id: 'en',
      name: 'EN',
      flag: ''
    },
    {
      id: 'de',
      name: 'DE',
      flag: ''
    },
  ]

  selectedLanguage = localStorage.getItem('language') || this.languages[0].id;

  @Input('longContent') content;

  constructor(public translate: TranslateService,
              public modalService:NgbModal,
              public router: Router,
              public userResolver: UserResolver,
              public authService: AuthService) {
  }

  ngOnInit(): void {
    this.selectedSidebarItem = this.router.url.split("/")[this.router.url.split("/").length-1];
  }

  onMobileNavItemClick(path) {
    const menuBtn = <HTMLElement>document.querySelector(".hamburger");
    const mobileMenu = <HTMLElement>document.querySelector(".mobile-nav");

    menuBtn.classList.toggle("is-active");
    mobileMenu.classList.toggle("is-active");

    this.router.navigate([path]);
  }

  setSelectedSidebarItem(itemClass) {
    this.selectedSidebarItem = itemClass;
  }

  sidebarMenuBtnToggle() {
    const sidebar = <HTMLElement>document.querySelector(".sidebar");
    const sidebarFooter = <HTMLElement>document.querySelector(".sidebar-footer");
    sidebar.classList.toggle("active")
    sidebarFooter.classList.toggle("active")
  }

  paymentMethod(method) {
    if(method == 'credit_card'){
      this.credit_clicked = true;
      this.billing_clicked = false;
    }else{
      this.billing_clicked = true;
      this.credit_clicked = false;
    }
  }

  menuActive(route, fixed = false){
    const reg = new RegExp('^'+route + (fixed ? '$' : ''));
    return !!reg.exec(this.router.url);
  }

  toLogin(){
    this.router.navigate(['auth/login'])
  }

  toRegister(){
    this.router.navigate(['auth/register'])
  }

  logout(){
    this.authService.logout();
    this.router.navigate(['/auth/login']);
  }
}
