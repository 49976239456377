import { Component, ViewChild} from '@angular/core';
import {Title} from '@angular/platform-browser';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {filter, map} from 'rxjs/operators';
import {NgcCookieConsentService, NgcInitializeEvent, NgcNoCookieLawEvent, NgcStatusChangeEvent} from 'ngx-cookieconsent';
import {Subscription} from 'rxjs';
import {LangChangeEvent, TranslateService} from '@ngx-translate/core';
import {resolve} from "chart.js/helpers";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {

  @ViewChild('contact') contact;

  private popupOpenSubscription!: Subscription;
  private popupCloseSubscription!: Subscription;
  private initializingSubscription!: Subscription;
  private initializedSubscription!: Subscription;
  private initializationErrorSubscription!: Subscription;
  private statusChangeSubscription!: Subscription;
  private revokeChoiceSubscription!: Subscription;
  private noCookieLawSubscription!: Subscription;

  constructor(private router: Router,
              private activatedRoute: ActivatedRoute,
              private ccService: NgcCookieConsentService,
              private translateService: TranslateService,
              private titleService: Title) {

      translateService.addLangs(['de', 'en']);
      translateService.setDefaultLang('en');
      translateService.use('en');
  }

  ngOnInit() {
    const appTitle = this.titleService.getTitle();

    this.router
      .events.pipe(
      filter(event => event instanceof NavigationEnd),
      map(() => {
        let child = this.activatedRoute.firstChild;
        while (child.firstChild) {
          child = child.firstChild;
        }
        if (child.snapshot.data['title']) {
          return child.snapshot.data['title'];
        }
        return appTitle;
      })
    ).subscribe((ttl: string) => {
      this.titleService.setTitle(ttl);
    });

    this.translateService.onLangChange.subscribe((event: LangChangeEvent) => {
      this.cookiesLang();
    });
    this.cookiesLang();

    this.popupOpenSubscription = this.ccService.popupOpen$.subscribe(
      () => {
        this.ccService.getConfig();
      });

    this.popupCloseSubscription = this.ccService.popupClose$.subscribe(
      () => {
        this.ccService.close(false);
      });

    this.initializingSubscription = this.ccService.initialize$.subscribe(
      (event: NgcInitializeEvent) => {
        this.ccService.getConfig().autoOpen = false;
      });



    this.statusChangeSubscription = this.ccService.statusChange$.subscribe(
      (event: NgcStatusChangeEvent) => {
        if (event.status === 'allow' || event.status === 'deny') {
          this.ccService.close(false);
        }
      });

    this.revokeChoiceSubscription = this.ccService.revokeChoice$.subscribe(
      () => {
        this.ccService.close(false);
      });

    this.noCookieLawSubscription = this.ccService.noCookieLaw$.subscribe(
      (event: NgcNoCookieLawEvent) => {
        this.ccService.close(false);
      });
  }

  cookiesLang() {
    this.translateService
      .get(['cookie_header', 'cookie_message', 'cookie_dismiss', 'cookie_allow', 'cookie_deny', 'cookie_link', 'cookie_policy'])
      .subscribe(data => {

        this.ccService.getConfig().content = this.ccService.getConfig().content || {};
        // Override default messages with the translated ones
        this.ccService.getConfig().content.header = data['cookie_header'];
        this.ccService.getConfig().content.message = data['cookie_message'];
        this.ccService.getConfig().content.dismiss = data['cookie_dismiss'];
        this.ccService.getConfig().content.allow = data['cookie_allow'];
        this.ccService.getConfig().content.deny = data['cookie_deny'];
        this.ccService.getConfig().content.link = data['cookie_link'];
        this.ccService.getConfig().content.policy = data['cookie_policy'];

        this.ccService.destroy(); // remove previous cookie bar (with default messages)
        this.ccService.init(this.ccService.getConfig()); // update config with translated messages
      });
  }

  ngOnDestroy() {
    this.popupOpenSubscription.unsubscribe();
    this.popupCloseSubscription.unsubscribe();
    this.initializingSubscription.unsubscribe();
    this.initializedSubscription.unsubscribe();
    this.initializationErrorSubscription.unsubscribe();
    this.statusChangeSubscription.unsubscribe();
    this.revokeChoiceSubscription.unsubscribe();
    this.noCookieLawSubscription.unsubscribe();
  }

}
