// @ts-ignore
import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';

import {AppComponent} from './app.component';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {RouterModule} from '@angular/router';
// @ts-ignore
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {HttpClient, HttpClientModule} from '@angular/common/http';
import {TranslateLoader, TranslateModule, TranslateService} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';

import {routes} from './app-routing.module';
import {AuthLayoutComponent} from './@dubistpflege/layouts/auth-layout/auth-layout.component';
import {SnotifyModule, SnotifyService, ToastDefaults} from 'ng-snotify';
import {inboundInterceptorProvider} from './@dubistpflege/interceptors/inbound.interceptor';
import {outboundInterceptorProvider} from './@dubistpflege/interceptors/outbound.interceptor';
import {AdminLayoutComponent} from './@dubistpflege/layouts/admin-layout/admin-layout.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {NgxSpinnerModule} from 'ngx-spinner';
import {LoadingBarHttpClientModule} from '@ngx-loading-bar/http-client';
import {LoadingBarRouterModule} from '@ngx-loading-bar/router';
import {LoadingBarModule} from '@ngx-loading-bar/core';
import {AngularSvgIconModule} from 'angular-svg-icon';
import {DialogModule} from './@dubistpflege/components/dialog/dialog.module';
import {NgcCookieConsentConfig, NgcCookieConsentModule} from 'ngx-cookieconsent';
import {NotFoundModule} from './@dubistpflege/components/not-found/not-found.module';
import {DirectivesModule} from './@dubistpflege/directives/directives.module';
import {AuthGuard} from './@dubistpflege/security-guards/auth.guard';
import {AuthPassGuard} from './@dubistpflege/security-guards/authpass.guard';
import { FacilitiesModalComponent } from './@dubistpflege/components/facilities-modal/facilities-modal.component';
import { NewOrderModalComponent } from './@dubistpflege/components/new-order-modal/new-order-modal.component';
import { CommentsModalComponent } from './@dubistpflege/components/comments-modal/comments-modal.component';

const cookieConfig: NgcCookieConsentConfig = {
    cookie: {
        domain: window.location.hostname
    },
    position: 'bottom-right',
    theme: 'classic',
    palette: {
        popup: {
            background: '#5223CA',
            text: '#ffffff',
            link: '#ffffff'
        },
        button: {
            background: '#0c9619',
            text: '#ffffff',
            border: 'transparent'
        }
    },
    type: 'opt-out',
};

@NgModule({
    declarations: [
        AppComponent,
        AuthLayoutComponent,
        AdminLayoutComponent,
        FacilitiesModalComponent,
        NewOrderModalComponent,
        CommentsModalComponent
    ],
    imports: [
        BrowserModule,
        NgbModule,
        HttpClientModule,
        CommonModule,
        ReactiveFormsModule,
        BrowserAnimationsModule,
        NgbModule,
        NgxSpinnerModule,
        FormsModule,
        SnotifyModule,
        LoadingBarHttpClientModule,
        LoadingBarRouterModule,
        LoadingBarModule,
        AngularSvgIconModule,
        DialogModule,
        NotFoundModule,
        DirectivesModule,
        NgcCookieConsentModule.forRoot(cookieConfig),
        RouterModule.forRoot(routes, {
            anchorScrolling: 'enabled'
        }),
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: httpTranslateLoader,
                deps: [HttpClient]
            }
        }),
    ],

    providers: [
        inboundInterceptorProvider,
        outboundInterceptorProvider,
        {provide: 'SnotifyToastConfig', useValue: ToastDefaults},
        SnotifyService,
        AuthGuard,
        AuthPassGuard
    ],

    bootstrap: [AppComponent]
})
export class AppModule {
    constructor(private translate: TranslateService) {
        translate.addLangs(['de', 'en']);
        translate.setDefaultLang('de');

        const language = localStorage.getItem('language');
        if (language) {
            translate.use(language);
        }
    }
}

export function httpTranslateLoader(http: HttpClient) {
    return new TranslateHttpLoader(http);
}
