<h6 class="w-100 text-center heading">{{subjectName}}</h6>
<div style="overflow: auto;" [ngStyle]="!userDataLoaded && {'visibility':'hidden'}">
    <div *ngIf="!facilities" class="comments-wrapper d-flex" style="min-height: 500px;" [ngClass]="{'justify-content-center': comments.length == 0}">
        <div *ngFor="let comment of comments"
                class="comment-ngfor-div"
                [ngClass]="{'comment-by-logged-in-user': userId == comment.user_id,
                            'comment-in-edit-mode': comment.id == commentIdToBeEdited}">
            <div *ngIf="userId == comment.user_id" type="button" data-toggle="dropdown" class="comment-option-button">
                <i class="bi bi-three-dots-vertical"></i>
            </div>
            <ul class="dropdown-menu">
                <li (click)="editComment(comment)" style="border-radius: 0.25rem 0.25rem 0 0;border-bottom: 1px solid rgba(0, 0, 0, 0.08);">
                    <i class="bi bi-pencil mr-2"></i>
                    <span>Bearbeiten</span>
                </li>
                <li (click)="deleteComment(comment)">
                    <i class="bi bi-trash mr-2"></i>
                    <span>Löschen</span>
                </li>
            </ul>
            <div class="comment">
                <div *ngIf="userId != comment.user_id" class="comment-user-name" [style.color]="getRandomColor(comment.user_id)">{{comment.user_name ? comment.user_name : "Benutzer " + comment.user_id}}</div>
                <div *ngIf="comment.id == commentIdToBeEdited" class="text-muted" style="padding-right: 10px;font-size: 12px;line-height: 16px;">Kommentar wird bearbeitet..</div>
                <div class="comment-text">{{comment.comment}}</div>
                <div class="comment-date text-muted">{{comment.updated_at != comment.created_at ? comment.updated_at : comment.created_at}}{{comment.updated_at != comment.created_at ? " (Bearbeitet)" : ""}}</div>
            </div>
        </div>
        <div *ngIf="comments.length == 0" class="text-muted text-center">Keine Kommentare</div>
    </div>

    <!-- GROUPED FACILITIES (JOB COLLECTOR) -->
    <div *ngIf="facilities" style="min-height: 500px;" [ngStyle]="groupedSubjects.length == 0 && {'display': 'flex', 'justify-content':'center', 'align-items':'center'}">
        <div *ngFor="let subject of groupedSubjects">
            <div style="padding: 15px 15px 0px;text-align: center;" class="text-muted">
                <div style="font-size: 14px;line-height: 18px;">{{subject.subjectName}}</div>
                <div *ngIf="subjectName == 'Unternehmen'" style="font-size: 12px;">Order ID: {{subject.orderId}}</div>
                <div *ngIf="subjectName == 'Standorte'" style="font-size: 12px;">ID: {{subject.id}}</div>
            </div>
            <div class="comments-wrapper d-flex" style="padding: 8px 15px 15px;" [ngClass]="{'justify-content-center': groupedSubjects.length == 0}">
                <div *ngFor="let comment of subject.comments;let i = index;"
                    class="comment-ngfor-div"
                    [ngClass]="{'comment-by-logged-in-user': userId == comment.user_id,
                                'comment-in-edit-mode': comment.id == commentIdToBeEdited}"
                    [ngStyle]="i > 0 && {'border-top': '1px solid rgba(0, 0, 0, 0.08);'}">
                    <div *ngIf="userId == comment.user_id && !this.facilities" type="button" data-toggle="dropdown" class="comment-option-button">
                        <i class="bi bi-three-dots-vertical"></i>
                    </div>
                    <ul class="dropdown-menu">
                        <li (click)="editComment(comment)" style="border-radius: 0.25rem 0.25rem 0 0;border-bottom: 1px solid rgba(0, 0, 0, 0.08);">
                            <i class="bi bi-pencil mr-2"></i>
                            <span>Bearbeiten</span>
                        </li>
                        <li (click)="deleteComment(comment)">
                            <i class="bi bi-trash mr-2"></i>
                            <span>Löschen</span>
                        </li>
                    </ul>
                    <div class="comment">
                        <div *ngIf="userId != comment.user_id" class="comment-user-name" [style.color]="getRandomColor(comment.user_id)">{{comment.user_name ? comment.user_name : "Benutzer " + comment.user_id}}</div>
                        <div *ngIf="comment.id == commentIdToBeEdited" class="text-muted" style="padding-right: 10px;font-size: 12px;line-height: 16px;">Kommentar wird bearbeitet..</div>
                        <div class="comment-text">{{comment.comment}}</div>
                        <div class="comment-date text-muted">{{comment.updated_at != comment.created_at ? comment.updated_at : comment.created_at}}{{comment.updated_at != comment.created_at ? " (Bearbeitet)" : ""}}</div>
                    </div>
                </div>
            </div>
        </div>
        <div *ngIf="groupedSubjects.length == 0" class="text-muted">
            <div>Keine Kommentare</div>
        </div>
    </div>

</div>
<div *ngIf="!facilities && userChatPermission?.access_right == 'READ_WRITE'" class="w-100" style="padding: 8px 30px 13px;background-color: rgb(233, 233, 240);border-top: 1px solid rgba(0, 0, 0, 0.1);">
    <div *ngIf="editMode" class="w-100 d-flex justify-content-between" style="font-size: 15px;padding: 0 4px;">
        <div class="text-muted">Kommentar bearbeiten</div>
        <div style="color: #5223ca;cursor: pointer" (click)="cancelEditing()">Abbrechen</div>
    </div>
    <input [(ngModel)]="input" type="text" class="w-100 form-control" (keydown.enter)="onEnterClickEvent($event)" (keyup)="onInputChange($event)">
    <div *ngIf="showEditWarning" class="text-danger mt-1" style="padding: 0 4px;font-size: 14px;">Der Kommentar darf nicht leer sein.</div>
</div>

