import { Directive, Input, OnInit, ElementRef, Renderer2 } from '@angular/core';
import {AuthService} from '../services/auth.service';

@Directive({
  selector: '[hasPermission]'
})
export class HasPermissionDirective implements OnInit {

  @Input('hasPermission') permissionCode: string;
  @Input('rw') readWrite: boolean = false;
  @Input('disable') disable: boolean = false;

  constructor(private _elemRef: ElementRef,
              private _renderer: Renderer2,
              private authService: AuthService) { }


  ngOnInit(): void {
    if(!this.authService.isLoggedIn())
      return;

    this.readWrite = this.hasParam(this.readWrite);
    this.disable = this.hasParam(this.disable);
    if (this.authService.hasPermission(this.permissionCode, this.readWrite)) {
      return;
    }

    if (this.disable) {
      this._renderer.setAttribute(this._elemRef.nativeElement, 'disabled', 'disabled');
    } else {
      this._elemRef.nativeElement.style.display = 'none';
    }

  }

  hasParam(value): boolean {
    return value === '';
  }
}
