<div class="modal-header">
   <div class="modal-title">
    <h4 class="mb-0 font-weight-bold">{{title}}</h4>
  </div>
  <a class="pointer" aria-label="Close" (click)="dismiss()">
    <img height="23" width="23" src="assets/icons/close-white.svg">
  </a>
</div>
<div class="modal-body">
  {{ message }}
</div>
<div *ngIf="type == 'confirm'" class="form-group text-right">
  <button type="button" class="btn text-dark font-weight-bold radius-sm" (click)="decline()">{{ btnNoText }}</button>
  <button type="button" class="btn btn-logon px-4 mr-4 ml-3" (click)="accept()">{{ btnYesText }}</button>
</div>

<div *ngIf="type == 'info'" class="form-group text-center">
  <button type="button" class="btn btn-logon px-4 mx-3" (click)="accept()">{{ btnOkText }}</button>
</div>
