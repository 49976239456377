<div class="component-container">
  <div class="sidebar active">
    <div class="logo">
      <div routerLink="/" style="display:flex;transition: all 1s ease;cursor: pointer;" (click)="setSelectedSidebarItem('users')">
        <img style="width: auto; height: 25px;" src="/assets/dubistpflege-logo.png" alt="LOGON" class=" d-lg-inline img-fluid">
      </div>
    </div>
    <i class="bi bi-list" id="sidebar-menu-btn" (click)="sidebarMenuBtnToggle()"></i>
    <ul class="nav-list" [class.disabled]="!authService.isLoggedIn()">

      <li style="margin-bottom: 4px;" [hasPermission]="'OVERVIEW'" [class.active]="menuActive('all-orders')"
          [class.selectedSidebarItem]="'all-orders' === selectedSidebarItem"
          (click)="setSelectedSidebarItem('all-orders')">
        <a [routerLink]="['/all-orders']">
          <i class="bi bi-layout-three-columns"></i>
          <span class="links-name">Alle Bestellungen</span>
        </a>
        <span class="li-tooltip">Alle Bestellungen</span>
      </li>

      <li style="margin-bottom: 4px;" [hasPermission]="'ORDERS'" [class.active]="menuActive('facilities')"
          [class.selectedSidebarItem]="'facilities' === selectedSidebarItem"
          (click)="setSelectedSidebarItem('facilities')">
        <a [routerLink]="['/facilities']">
          <!-- <i class="bi bi-list-task"></i> -->
          <i class="bi bi-building"></i>
          <span class="links-name">Standorte</span>
        </a>
        <span class="li-tooltip">Standorte</span>
      </li>
      
      <li style="margin-bottom: 4px;" [hasPermission]="'JOB_COLLECTOR'" [class.active]="menuActive('job-collector')"
          [class.selectedSidebarItem]="'job-collector' === selectedSidebarItem"
          (click)="setSelectedSidebarItem('job-collector')">
        <a [routerLink]="['/job-collector']">
          <!-- <i class="bi bi-collection"></i> -->
          <i class="bi bi-briefcase"></i>
          <span class="links-name">Job Collector</span>
        </a>
        <span class="li-tooltip">Job Collector</span>
      </li>

      <li style="margin-bottom: 4px;" [class.active]="menuActive('/jobs', true)"
          [class.selectedSidebarItem]="'jobs' === selectedSidebarItem"
          [hasPermission]="'JOB'"
          (click)="setSelectedSidebarItem('jobs')">
        <a [routerLink]="['/jobs']">
          <!-- <i class="bi bi-briefcase"></i> -->
          <i class="bi bi-check2-circle"></i>
          <!-- <i class="bi bi-check-circle"></i> -->
          <!-- <i>
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-send-check" viewBox="0 0 16 16">
              <path d="M15.964.686a.5.5 0 0 0-.65-.65L.767 5.855a.75.75 0 0 0-.124 1.329l4.995 3.178 1.531 2.406a.5.5 0 0 0 .844-.536L6.637 10.07l7.494-7.494-1.895 4.738a.5.5 0 1 0 .928.372l2.8-7Zm-2.54 1.183L5.93 9.363 1.591 6.602l11.833-4.733Z"/>
              <path d="M16 12.5a3.5 3.5 0 1 1-7 0 3.5 3.5 0 0 1 7 0Zm-1.993-1.679a.5.5 0 0 0-.686.172l-1.17 1.95-.547-.547a.5.5 0 0 0-.708.708l.774.773a.75.75 0 0 0 1.174-.144l1.335-2.226a.5.5 0 0 0-.172-.686Z"/>
            </svg>
          </i> -->
          <span class="links-name">Validierung</span>
        </a>
        <span class="li-tooltip">Validierung</span>
      </li>

      <hr style="width: 210px;opacity: 0.5;" class="text-white bg-white">

      <li style="margin-bottom: 4px;" [hasPermission]="'USER'" [class.active]="menuActive('/users', true)"
          [class.selectedSidebarItem]="'users' === selectedSidebarItem"
          (click)="setSelectedSidebarItem('users')">
        <a [routerLink]="['/users']">
          <i class="bi bi-people"></i>
          <span class="links-name">{{'Benutzer' | translate}}</span>
        </a>
        <span class="li-tooltip">{{'Benutzer' | translate}}</span>
      </li>

      <li [class.active]="menuActive('settings')"
          [class.selectedSidebarItem]="'settings' === selectedSidebarItem"
          (click)="setSelectedSidebarItem('settings')">
        <a [routerLink]="['/settings']">
          <i class="bi bi-gear"></i>
          <span class="links-name">{{'Einstellungen' | translate}}</span>
        </a>
        <span class="li-tooltip">{{'Einstellungen' | translate}}</span>
      </li>
    </ul>

    <div class="sidebar-footer">
      <p class="footer-links" style="margin-bottom: 0px;">
         <a target="_blank" href="legaldocs/impressum.html">{{'impressum' | translate}}</a><br/>
        <a target="_blank" href="legaldocs/datenschutz.html">{{'datenschutz' | translate}}</a><br/>
         <a target="_blank" href="assets/pdf/TermsOfUse.pdf">{{'terms_of_use' | translate}}</a><br/>
        <a target="_blank" href="assets/pdf/T&C.pdf">{{'landing_terms_conditions' | translate}}</a>
      </p>

      <p class="mb-0" style="margin-top: 3px;font-size: 0.8rem;color: #c1c1c1;">&copy; {{currentYear}} {{'dubistpflege_admin' | translate}}</p>
    </div>

    <div class="profile-content">
      <div class="profile">
        <div class="profile-details">
          <img [src]="authService.getUser()?.avatar_url || 'assets/icons/profile-white.png'" class="mr-2 admin-profile-image">
          <div class="name">{{authService.isLoggedIn() ? authService.getUserName() : ' '}}</div>
        </div>
        <i class="bi bi-box-arrow-left" id="log-out" (click)="logout()"></i>
      </div>
    </div>
  </div>

  <div class="selected-content-container">
    <div class="selected-content">
      <router-outlet></router-outlet>
    </div>
  </div>
</div>
