import {HttpClient} from '@angular/common/http';
import {BehaviorSubject, Observable, throwError} from 'rxjs';
import {catchError, finalize, tap} from 'rxjs/operators';
import {environment} from '../../../environments/environment';


export class AbstractService {

  private _getting: BehaviorSubject<boolean> = new BehaviorSubject(false);
  private _posting: BehaviorSubject<boolean> = new BehaviorSubject(false);
  private _putting: BehaviorSubject<boolean> = new BehaviorSubject(false);

  readonly getting$: Observable<boolean> = this._getting.asObservable();
  readonly posting$: Observable<boolean> = this._posting.asObservable();
  readonly putting$: Observable<boolean> = this._putting.asObservable();

  constructor(protected http: HttpClient) {}

  buildUrl(path: string): string {
    return environment.api_url + path;
  }

  get(path, options = {}): Observable<any> {
    this._getting.next(true);
    return this.http.get(`${this.buildUrl(path)}`, options).pipe(
      finalize(() => this._getting.next(false))
    );
  }

  post(path, payload: any, options = {}) {
    this._posting.next(true);
    return this.http.post(this.buildUrl(path), payload, options).pipe(
      finalize(() => this._posting.next(false))
    );
  }

  put(path, payload, options = {}) {
    this._putting.next(true);
    return this.http.put(`${this.buildUrl(path)}`, payload, options).pipe(
      finalize(() => this._putting.next(false))
    );
  }

  delete(path, options = {}) {
    this._putting.next(true);
    return this.http.delete(`${this.buildUrl(path)}`, options).pipe(
      finalize(() => this._putting.next(false))
    );
  }

  silentOption(){
    return {headers: {'X-Silent': 'true'}};
  }
}


