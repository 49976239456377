import {AuthService} from './../services/auth.service';
import {Injectable, Provider} from '@angular/core';
import {HttpInterceptor, HttpEvent, HttpHandler, HttpRequest, HTTP_INTERCEPTORS} from '@angular/common/http';

import {Router} from '@angular/router';
import {Observable} from 'rxjs';
import {TranslateService} from '@ngx-translate/core';


@Injectable()
export class OutboundInterceptor implements HttpInterceptor {
    constructor(private router: Router, private translate: TranslateService, private authService: AuthService) {
    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const token = localStorage.getItem('token');
        const language = localStorage.getItem('language') || this.translate.getDefaultLang();
        const sessionId = this.authService.getSession();

        const headers = {'X-Session': sessionId, 'X-Path': this.router.url};

        //Workaround for upload
        if (req.method !== 'POST') {
            headers['Content-type'] = 'application/json';
        }

        if (token != null) {
            headers['Authorization'] = `Bearer ${token}`;
        }

        if (language != null) {
            headers['X-Localization'] = language;
        }

        // Remove null or undefined query params
        let params = req.params;
        for (const key of req.params.keys()) {
            if (params.get(key) === undefined || params.get(key) === null) {
                params = params.delete(key, undefined);
            }
        }

        req = req.clone({setHeaders: headers, params: params});
        return next.handle(req);
    }
}

export const outboundInterceptorProvider: Provider = {
    provide: HTTP_INTERCEPTORS,
    useClass: OutboundInterceptor,
    multi: true
};
